import {defineStore} from "pinia";

export const useDialogStateStore = defineStore("dialogStateStore", {
  state: () => ({
    totalFormats: 0,  // Total formats to view (from DialogDetailFooterView)
    currentFormatIndex: 1,  // Start at the first format
    visualizedFormats: {},  // Track visualized formats
    canCloseDialog: false,  // Initially, the dialog cannot be closed
  }),
  getters: {
    // Checks if all formats are visualized
    allFormatsViewed(state) {
      console.log(`inside the store  visualizedFormats ${JSON.stringify(state.visualizedFormats)}  and totalFormats ${state.totalFormats}`)
      return Object.keys(state.visualizedFormats).length === state.totalFormats;
    },
  },
  actions: {
    // Set total formats, usually from DialogDetailFooterView
    setTotalFormats(total) {
      this.totalFormats = total;
    },

    // Set current format index when user navigates
    setCurrentFormatIndex(index) {
      this.currentFormatIndex = index;
    },

    // Mark a format as visualized
    markFormatAsViewed(index) {
      this.visualizedFormats[index] = true;
    },

    // Check if the dialog can be closed
    updateDialogCloseStatus() {
      this.canCloseDialog = this.allFormatsViewed && this.visualizedFormats.size > 0;
    },

    // Optionally, reset visualized formats when a new session starts
    resetVisualization() {
      delete this.visualizedFormats;
    },
  },
});

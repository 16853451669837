<template>
  <v-dialog
      v-if="projectDetails"
      :model-value="dialog"
      fullscreen
      persistent
      @update:model-value="(newValue) => (dialog = newValue)"
  >
    <v-card>
      <v-card-title class="d-flex justify-space-between sticky-title header">
        <div class="d-flex align-center header-first-block">
          <div
              class="headline header-icon-menu-item mx-4"
              @click="closeDialog()"
          >
            <b>Projet</b> : {{ projectDetails.title }}
          </div>
          <v-divider vertical></v-divider>
          <!-- view start for task  -->
          <div v-if="!modelLargeView" class="mx-4">
            <v-select
                :items="tasksList"
                :model-value="
                taskLabel === '' ? this.selectedTaskTitle : taskLabel
              "
                bg-color="#FFFFFF"
                class="header-select"
                flat
                variant="plain"
            >
              <template v-slot:item="{ item }" variant="outlined">
                <v-list-item
                    class="header-icon-menu-item"
                    @click="this.getFilesByTaskId(item)"
                >{{ item.raw.title }}
                </v-list-item>
              </template>
            </v-select>
          </div>
          <div v-else class="header-headline mx-4">
            <b>Tâche</b> : {{ this.taskLabel }}
          </div>
          <v-divider v-if="modelLargeView" vertical></v-divider>
          <!-- view start for file format  -->
          <div v-if="modelLargeView" class="header-headline mx-4">
            <b> {{ translate.translateFormat(this.selectedRev.file.view) }}</b>
          </div>

          <v-divider v-if="modelLargeView" vertical></v-divider>
          <!-- view list start for revision #number-->
          <div
              v-if="modelLargeView && !revisionChangeAccess(this.selectedRev)"
              class="mx-4 headline"
          >
            <v-select
                v-model="this.revisionLabel"
                :items="revisionsList.fileRevisions"
                bg-color="#FFFFFF"
                flat
                icon="mdi-update"
                variant="plain"
            >
              <template v-slot:prepend-inner>
                <v-icon class="first-block-icon">mdi-update</v-icon>
              </template>
              <template v-slot:item="{ item, index }">
                <v-list-item
                    class="header-icon-menu-item"
                    @click="
                    getFileByRevisionId(
                      item.raw,
                      revisionsList.fileRevisions.length - index
                    )
                  "
                >
                  {{
                    'Version #'
                  }}{{ revisionsList.fileRevisions.length - index }}
                </v-list-item>
              </template>
            </v-select>
          </div>
          <div
              v-if="modelLargeView && revisionChangeAccess(this.selectedRev)"
              class="header-headline mx-4"
          >
            <b> {{ this.revisionLabel }} </b>
          </div>
          <!-- view list end -->
          <v-divider v-if="modelLargeView" vertical></v-divider>
          <!-- versions list start (need to be dynamic)-->
          <div v-if="modelLargeView" class="mx-4 header-headline">
            <v-icon class="inner-select-icon" icon="mdi-update"></v-icon>
            {{
              $filters.formatDateDMYHMSversion(
                  this.selectedRevisionUpdatedDate,
                  false
              )
            }}
          </div>
          <!-- versions list end -->
          <v-divider v-if="modelLargeView" vertical></v-divider>
          <div v-if="modelLargeView" class="mx-2">
            <v-menu v-if="!user.is_client" class="header-icon-menu">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props">
                  <v-icon
                      :class="{
                      'eye-donwload-Icon': selectedRevisionVisibility,
                      'eye-donwload-Icon-Off': !selectedRevisionVisibility,
                      'view-icon': true,
                    }"
                      :icon="
                      selectedRevisionVisibility
                        ? 'mdi-eye-outline'
                        : 'mdi-eye-off-outline'
                    "
                  ></v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    v-for="(item, i) in itemsEye"
                    :key="i"
                    class="header-icon-menu-item"
                >
                  <v-list-item-title
                      @click="updateVisibilityByClient(selectedRev.id, i)"
                  >
                    <v-list-item-icon class="first-block-icon">
                      <v-icon class="inner-select-icon"
                      >{{ item.icon }}
                      </v-icon>
                    </v-list-item-icon>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <!--download  start -->
          <div v-if="modelLargeView" class="mx-2">
            <v-menu class="header-icon-menu">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props">
                  <v-icon icon="mdi-download-outline"></v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item class="header-icon-menu-item">
                  <v-list-item-title @click="downloadPdf">
                    <v-icon
                        class="inner-select-icon"
                        icon="mdi-file-pdf-box"
                    ></v-icon>
                    Fichier PDF
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="header-icon-menu-item">
                  <v-list-item-title @click="downloadJPG">
                    <v-icon
                        class="inner-select-icon"
                        icon="mdi-image-album"
                    ></v-icon>
                    Image JPG
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <!--download  end-->
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex justify-space-between header-second-block">
          <v-btn
              v-if="modelLargeView"
              :class="isCommentPopupOpen ? 'comment-enabled-btn' : 'comment-btn'"
              rounded="lg"
              size="x-large"
              variant="text"
              @click="openCommentDialog"
          >
            <v-icon class="comment-btn-icon"> mdi-comment-outline</v-icon>
            {{ this.comments.length }} commentaires
          </v-btn>
        </div>
        <div
            v-if="!user.is_client"
            class="d-flex justify-space-between header-second-block mx-2"
        >
          <v-btn
              v-if="
              modelLargeView &&
              this.taskStore.commentsExistForSelectedTask &&
              this.selectedRev.status.id == status_in_revision
            "
              class="comment-btn"
              rounded="lg"
              size="x-large"
              variant="text"
              @click="addCommentGRC(taskId, add_comment_msg)"
          >
            informer graphiste
          </v-btn>
          <v-btn
              v-if="
              modelLargeView && this.selectedRev.status.id == status_to_correct
            "
              class="comment-btn"
              rounded="lg"
              size="x-large"
              variant="text"
              @click="addCommentGRC(taskId, lunch_fix_msg)"
          >
            Lancer la correction
          </v-btn>
        </div>
        <v-spacer></v-spacer>

        <v-icon class="header-third-block" @click="closeDialog"
        >mdi-close
        </v-icon
        >
      </v-card-title>

      <v-card-text
          v-if="!modelLargeView && this.fileList"
          :style="{ transform: `scale(${zoomLevel})` }"
          style="background: #30322f"
      >
        <div class="view-container">
          <!-- First row for views except 'other' -->
          <div class="item-list__wrapper">
            <div v-for="(item, index) in this.fileList" :key="index" class="view-row__container">
              <div v-if="item.view != 'other'" class="view-row view">
                <div
                    v-show="
                    (user.is_client && item.revisions[0].visible) ||
                    !user.is_client
                  "
                    style="position: relative"
                >
                  <div
                      :style="{ backgroundColor: item.revisions[0].status.color }"
                      class="view-image-text"
                  >
                    <v-icon class="mx-2">mdi-magnify</v-icon>
                    {{ item.revisions[0].status.title }}
                  </div>
                  <img
                      v-if="item.view == 'desktop'"
                      :src="getImageSource(item.preview)"
                      :style="{
                      border: '10px solid ' + item.revisions[0].status.color,
                    }"
                      class="image imageDesktop custom-cursor"
                      @click="showModel(item.id, true)"
                  />
                  <img
                      v-if="item.view == 'laptop'"
                      :src="getImageSource(item.preview)"
                      :style="{
                      border: '10px solid ' + item.revisions[0].status.color,
                    }"
                      class="image imageLaptop custom-cursor"
                      @click="showModel(item.id, true)"
                  />
                  <img
                      v-if="item.view == 'tablet'"
                      :src="getImageSource(item.preview, true)"
                      :style="{
                      border: '10px solid ' + item.revisions[0].status.color,
                    }"
                      class="image imageTablet custom-cursor"
                      @click="showModel(item.id, true)"
                  />
                  <img
                      v-if="item.view == 'mobile'"
                      :src="getImageSource(item.preview)"
                      :style="{
                      border: '10px solid ' + item.revisions[0].status.color,
                    }"
                      class="image imageMobile custom-cursor"
                      @click="showModel(item.id, true)"
                  />
                  <!-- Info Section -->
                  <div>
                    <div>
                      <v-icon class="view-icon">{{
                          getViewIcon(item.view)
                        }}
                      </v-icon>
                    </div>
                    <div class="view-type">
                      <div class="type-text">{{ getViewText(item.view) }}</div>
                      <hr class="type-divider"/>
                    </div>
                    <div class="view-title">{{ item.title }}</div>
                    <div class="view-details">
                      <div>
                        <v-icon class="view-details-icon">mdi-av-timer</v-icon>
                        <span class="view-details-text"
                        >Version #{{ item.revisions_nb }}</span
                        >
                      </div>
                      <div>
                        <v-icon class="view-details-icon"
                        >mdi-calendar-range
                        </v-icon
                        >
                        {{
                          $filters.formatDateDMY(item.revisions[0].updated_at)
                        }}
                      </div>
                      <div>
                        <v-icon class="view-details-icon">mdi-av-timer</v-icon>
                        {{
                          $filters.formatTimeHMS(item.revisions[0].updated_at)
                        }}
                      </div>
                    </div>
                    <div class="view-comment-red">
                      <img
                          :src="getPictogram('message-white')"
                          class="pictogram me-1"
                      />
                      {{ item.comment_statistics.untreated_comments }}
                      {{ untreatedTerm }}
                    </div>
                    <div class="view-comment-green">
                      <img
                          :src="getPictogram('message-check-white')"
                          class="pictogram me-1"
                      />{{ item.comment_statistics.treated_comments }}
                      {{ treatedTerm }}
                    </div>
                    <div class="view-comment-grey">
                      <img
                          :src="getPictogram('message-xmark-white')"
                          class="pictogram me-1"
                      />{{ item.comment_statistics.refused_comments }}
                      {{ rejectedTerm }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="other-list__wrapper">
            <div
                v-if="
                (other_item = this.fileList.filter(
                  (item) => item.view == 'other'
                )[0])
              "
            >
              <hr style="border-color: #fff; margin: 20px 0 ; width:100%"/>
              <div class="view" style="display: inline-block">
                <!-- Separator Line -->

                <div
                    v-show="
                    (user.is_client && other_item.revisions[0].visible) ||
                    !user.is_client
                  "
                    style="position: relative"
                >
                  <div
                      :style="{
                      backgroundColor: other_item.revisions[0].status.color,
                    }"
                      class="view-image-text"
                  >
                    <v-icon class="mx-2">mdi-magnify</v-icon>
                    {{ other_item.revisions[0].status.title }}
                  </div>
                  <img
                      :src="getImageSource(other_item.preview)"
                      :style="{
                      border:
                        '10px solid ' + other_item.revisions[0].status.color,
                    }"
                      class="image imageDesktop custom-cursor"
                      @click="showModel(other_item.id, true)"
                  />
                  <!-- Info Section -->
                  <div>
                    <div>
                      <v-icon class="view-icon">{{
                          getViewIcon(other_item.view)
                        }}
                      </v-icon>
                    </div>
                    <div class="view-type">
                      <div class="type-text">
                        {{ getViewText(other_item.view) }}
                      </div>
                      <hr class="type-divider"/>
                    </div>
                    <div class="view-title">{{ other_item.title }}</div>
                    <div class="view-details">
                      <div>
                        <v-icon class="view-details-icon">mdi-av-timer</v-icon>
                        <span class="view-details-text"
                        >Version #{{ other_item.revisions_nb }}</span
                        >
                      </div>
                      <div>
                        <v-icon class="view-details-icon"
                        >mdi-calendar-range
                        </v-icon
                        >
                        {{
                          $filters.formatDateDMY(
                              other_item.revisions[0].updated_at
                          )
                        }}
                      </div>
                      <div>
                        <v-icon class="view-details-icon">mdi-av-timer</v-icon>
                        {{
                          $filters.formatTimeHMS(
                              other_item.revisions[0].updated_at
                          )
                        }}
                      </div>
                    </div>
                    <div class="view-comment-red">
                      <img
                          :src="getPictogram('message-white')"
                          class="pictogram me-1"
                      />
                      {{ other_item.comment_statistics.untreated_comments }}
                      {{ untreatedTerm }}
                    </div>
                    <div class="view-comment-green">
                      <img
                          :src="getPictogram('message-check-white')"
                          class="pictogram me-1"
                      />{{ other_item.comment_statistics.treated_comments }}
                      {{ treatedTerm }}
                    </div>
                    <div class="view-comment-grey">
                      <img
                          :src="getPictogram('message-xmark-white')"
                          class="pictogram me-1"
                      />{{ other_item.comment_statistics.refused_comments }}
                      {{ rejectedTerm }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-text v-else style="background: #30322f">
        <v-row>
          <v-col cols="12" style="background: #30322f">
            <v-col :cols="isCommentPopupOpen ? 8 : 12" class="mockup-view" :style="{
              maxWidth : isCommentPopupOpen ?'70%' : ''
            }">

              <div v-for="file in paginatedModels" id="element-to-convert" :key="file.id" class="image-container"
                   :style="file.view == 'mobile' ? { width: '376px'} : {}"
                   style="overflow: auto; cursor: grab">
                <v-responsive style=" overflow: auto ; cursor: default"
                              :style="file.view == 'mobile' ? { height: '670px !important' } : {}">
                  <img ref="image" id="image-to-download" :src="getImageSource(file.preview)" alt="Gallery Image"
                       :class="imagePined ? 'image-container-with-filter' : ''"
                       :style="{
                          transform: `scale(${zoomLevel}) translate(${transformX}%, ${transformY}px) `,
                          transition: 'transform 0.1s ease',
                          maxWidth: isCommentPopupOpen ? '' : '100%'
                        }"
                       class="image-container-responsive"
                       @mousedown="handleMouseDown"
                       @mouseup="handleMouseUp"
                       @mouseleave="stopDragging"
                       @click="setNewCommentPosition"
                  />
                  <template v-if="isCommentPopupOpen && zoomLevel == 1">
                    <div
                        v-for="(comment, index) in comments"
                        :key="index"
                        :style="{
                        top: `${
                          (comment.pins.CoordinateX / 100) * imageHeight
                        }px`,
                        left: `${
                          (comment.pins.CoordinateY / 100) * imageWidth
                        }px`,
                      backgroundColor: comment.status.color,
                    }" class="badge" :id="`pin-${comment.id}`" @click="showComment(index)">
                      {{
                        this.user.is_client
                            ? comment.commentNbrClient
                            : comment.commentNbrAdmin
                      }}
                    </div>
                    <!-- Pin Icon -->
                    <div v-if="
                      imagePined &&
                      (commentPin.CoordinateX != 0 ||
                        commentPin.CoordinateY != 0)
                    "
                         :style="{
                        top: `${(commentPin.CoordinateX / 100) * imageHeight
                          }px`,
                        left: `${(commentPin.CoordinateY / 100) * imageWidth
                          }px`,
                      }" class="pin-icon">
                      <v-icon :color="'#05A9F4'">mdi-pin-outline</v-icon>
                    </div>
                  </template>
                </v-responsive>
              </div>
              <v-card-actions
                  v-if="modelLargeView"
                  class="d-flex justify-space-between sticky-footer"
              >
                <div class="d-flex align-center">
                  <v-btn icon @click="zoomOut">
                    <v-icon>mdi-magnify-minus-outline</v-icon>
                  </v-btn>
                  <div class="select-wrapper">
                    <span>{{ Math.round(zoomLevel * 100) }} % </span>
                  </div>

                  <v-btn icon @click="zoomIn">
                    <v-icon>mdi-magnify-plus-outline</v-icon>
                  </v-btn>
                </div>
                <!-- //TODO::bob:in client case   -->
                <div
                    v-if="user.is_client && selectedRev.status.id === 4"
                    class="d-flex align-center"
                >
                  <!--//TODO::bob::start 2 btn previews and next  -->
                  <v-btn
                      v-if="!(currentReviewNP == 0)"
                      class="btn-change-file"
                      @click="prevModelReviewMode"
                  >
                    <!--                  //TODO:: add condition to prev -->
                    Précedent
                    <!-- il y a d'autres maquettes à Réviser -->
                  </v-btn>
                  <!-- // TODO::bob:: btn with icones  @click="showRevisionsBySelectedViewType(file.id, false )"-->
                  <div v-for="file in filesListToReview">
                    <span
                        :key="file.id"
                        :style="{
                          color: file.view === selectedRevNP.view ? '#e74c3c' : '#FFFFFF',
                          cursor:'default',
                          fontsize: '14px'
                        }"
                        class="mx-2"
                    >
                      <v-icon class="mr-1">
                        {{ getViewIcon(file.view) }}
                      </v-icon
                      >
                      {{ getViewText(file.view) }}
                      <!-- {{ filesListToReviewByMaketSelected[this.currentReviewNP].view }} -->
                    </span>
                  </div>

                  <v-btn
                      v-if="!(filesListToReview.length - 1 === currentReviewNP)"
                      class="btn-change-file"
                      @click="nextModelReviewMode"
                  >
                    Suivant
                  </v-btn>

                  <!--//TODO::bob::End 2 btn previews and next  -->
                  <v-btn
                      v-if="
                      filesListToReview.length - 1 == currentReviewNP &&
                      enableSendCommentButton
                    "
                      class="btn-change-file"
                      @click="
                      openCommentSubmitPermission({
                        actionType: 'sendComments',
                      })
                    "
                  >
                    <span>Envoyer mes commentaires</span>
                    <v-icon class="ml-3">mdi-send</v-icon>
                  </v-btn>

                  <!--//TODO::bob::Valider les maquettes -->
                  <v-btn
                      v-if="
                      filesListToReview.length - 1 == currentReviewNP &&
                      enableValidateButton
                    "
                      class="btn-validate-files "
                      @click="
                      openCommentSubmitPermission({
                        actionType: 'validateMockups',
                      })
                    "
                  >
                    <span>Valider les maquettes</span>
                    <v-icon class="ml-3">mdi-send</v-icon>
                  </v-btn>
                  <!--//TODO::bob::2 End btn comment and validate  -->
                </div>
                <div v-else class="d-flex align-center">
                  <v-icon v-if='!user.is_client' :disabled="currentModel === 1" @click="prevModel"
                  >mdi-arrow-left
                  </v-icon>
                  <v-btn v-if='user.is_client'
                         :disabled="currentModel === 1"
                         class="btn-change-file-client-prev"
                         @click="prevModel"
                  >Précedent
                  </v-btn>
                  <span class="mx-2">{{
                      `Maquette ${currentModel} / ${totalModels}
                    `
                    }}</span>

                  <v-icon
                      v-if="!user.is_client"
                      :disabled="currentModel === totalModels"
                      @click="nextModel"
                  >
                    mdi-arrow-right
                  </v-icon>
                  <v-btn
                      v-if="user.is_client"
                      class="btn-change-file-client-next"
                      :disabled="currentModel === totalModels"
                      @click="nextModel"
                  >
                    Suivant
                  </v-btn>
                </div>
                <!--view paginate -->

                <div class="d-flex align-center">
                </div>
              </v-card-actions>
            </v-col>
            <v-col v-if="isCommentPopupOpen" cols="4">
              <v-navigation-drawer
                  v-model="isCommentPopupOpen"
                  :style="{
                  width: '100%',
                  'max-width': 'none',
                  'z-index': '1004',
                  transform: 'translateX(0%)',
                  position: 'fixed',
                  height: 'calc(100% - 64px)',
                  top: '64px',
                  bottom: '0px',
                }"
                  class="comment-popup"
                  location="right"
                  permanent
              >
                <template v-slot:prepend>
                  <v-form class="comment-form" @submit.prevent="saveComment">
                    <v-text-field
                        id="comment"
                        v-model="commentForm.content"
                        :disabled="
                        (!this.user.is_client && selectedRevisionVisibility) ||
                        (this.user.is_client && this.selectedRev.status.id != 4)
                      "
                        color="#05a9f4"
                        placeholder="Saisir un commentaire"
                        textarea
                        variant="outlined"
                    >
                      <template v-slot:append-inner>
                        <v-icon
                            :color="imagePined ? '#05A9F4' : ''"
                            @click="applyFilter"
                        >mdi-pin-outline
                        </v-icon
                        >
                      </template>
                    </v-text-field>

                    <div class="d-flex justify-end">
                      <v-btn
                          :disabled="
                          (!this.user.is_client &&
                            selectedRevisionVisibility) ||
                          (this.user.is_client &&
                            this.selectedRev.status.id != 4)
                        "
                          class="me-2 cancel-btn"
                          variant="flat"
                          @click="cancelComment"
                      >
                        Annuler
                      </v-btn>
                      <v-btn
                          :class="
                          this.commentPin.CoordinateX != 0 &&
                          this.commentPin.CoordinateY != 0
                          ? 'vdt-enabled-btn'
                          : 'vdt-btn'
                        " :disabled="validerDisabled  && commentForm.content " class="custom-disabled-btn"
                          type="submit"
                          variant="text"
                          @click="reloadData(this.selectedRev.file.id)">
                        Valider
                      </v-btn>
                    </div>
                  </v-form>
                </template>

                <!--list of  start-->

                <v-virtual-scroll
                    v-if="sortedComments.length"
                    :items="sortedComments"
                    item-height="100"
                    nav
                    style="margin-bottom: 120px; padding-top: 21px; padding-left: 12px;"
                >
                  <template v-slot:default="{ item: comment, index }">
                    <v-list-item
                        :key="index"
                        :id="`comment-${comment.id}`"
                        :class="{ 'highlighted': highlightedComments[index] }"
                        class="my-2"
                    >
                      <template v-if="comment.parent == null"
                                v-slot:prepend>
                        <v-badge :color="comment.status.color"
                                 @click="showPin(comment.id , comment.status.color)"
                                 avatar
                                 bordered class="badge-avatar badge" overlap>
                          <template v-slot:badge>
                            <div>
                              {{
                                this.user.is_client
                                    ? comment.commentNbrClient
                                    : comment.commentNbrAdmin
                              }}
                            </div>
                          </template>
                          <v-avatar
                              :image="getAvatar(comment.user.avatar)"
                          ></v-avatar>
                        </v-badge>
                      </template>
                      <template v-slot:append>
                        <div class="text-center" v-if="this.user.is_client">
                          <v-menu v-if=" comment.user.id == this.user.id">
                            <template v-slot:activator="{ props }">
                              <v-btn color="grey-lighten-1" icon="mdi-dots-horizontal"
                                     style="position: absolute; top: 0; right: 0" v-bind="props" variant="text"></v-btn>
                            </template>

                            <v-list class="comment-settings">
                              <v-list-item v-for="(commentStatus, i) in getCommentSettings(comment)" :key="i">
                                <v-list-item-title @click="handleSettingActions(commentStatus, comment)">
                                  <v-icon v-if="commentStatus.title === settingActions.treat"
                                          class="mr-2 settings-icon">
                                    mdi-check-network-outline
                                  </v-icon>
                                  <v-icon v-if="commentStatus.title === settingActions.edit" class="mr-2 settings-icon">
                                    mdi-pencil
                                  </v-icon>
                                  <v-icon v-if="commentStatus.title === settingActions.refuse"
                                          class="mr-2 settings-icon">
                                    mdi-close-network-outline
                                  </v-icon>
                                  {{ commentStatus.title }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                        <div class="text-center" v-else>
                          <v-menu>
                            <template v-slot:activator="{ props }">
                              <v-btn
                                  color="grey-lighten-1"
                                  icon="mdi-dots-horizontal"
                                  style="position: absolute; top: 0; right: 0"
                                  v-bind="props"
                                  variant="text"
                              ></v-btn>
                            </template>

                            <v-list class="comment-settings">
                              <v-list-item v-for="(commentStatus, i) in getCommentSettings(comment)" :key="i">
                                <v-list-item-title @click="handleSettingActions(commentStatus, comment)">
                                  <v-icon v-if="commentStatus.title === settingActions.treat"
                                          class="mr-2 settings-icon">
                                    mdi-check-network-outline
                                  </v-icon>
                                  <v-icon v-if="commentStatus.title === settingActions.edit" class="mr-2 settings-icon">
                                    mdi-pencil
                                  </v-icon>
                                  <v-icon v-if="commentStatus.title === settingActions.refuse"
                                          class="mr-2 settings-icon">
                                    mdi-close-network-outline
                                  </v-icon>
                                  {{ commentStatus.title }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                      </template>

                      <v-list-item-content>
                        <div style="padding-left: 20px" class="comment-user"
                             @click="showPin(comment.id , comment.status.color)">
                          <div style="
                              font: normal normal bold 14px/19px Roboto;
                              color: #494949;
                            "
                          >
                            {{ comment.user.username }}
                          </div>
                          <div
                              style="
                              font: normal normal normal 12px/16px Roboto;
                              color: #707070;
                            "
                          >
                            {{
                              $filters.formatDateDMYHMS(
                                  comment.created_at,
                                  true
                              )
                            }}
                          </div>
                        </div>
                        <div>
                          <div v-if="comment.isEditing" style="padding-left: 20px">
                            <v-form @submit.prevent="saveEditComment(comment)">
                              <v-text-field
                                  v-model="comment.content"
                                  class="edit-comment-input"
                                  color="#05a9f4"
                                  multi-line
                                  placeholder="Modifier le commentaire"
                                  variant="outlined"
                              ></v-text-field>
                              <div class="pa-4 d-flex justify-end">
                                <v-btn class="me-2 cancel-btn" variant="flat" @click="comment.isEditing = false">
                                  Annuler
                                </v-btn>
                                <v-btn
                                    :class="!comment.content || comment.content.trim() === '' ? 'vdt-btn' : 'vdt-enabled-btn'"
                                    :disabled="!comment.content || comment.content.trim() === ''"
                                    type="submit"
                                    variant="text"
                                >
                                  Valider
                                </v-btn>
                              </div>
                            </v-form>
                          </div>
                          <div v-else style="padding-left: 20px">
                            <div class="comment">
                              {{ comment.content }}
                            </div>
                          </div>

                          <div v-else style="padding-left: 20px">
                            <div class="comment">
                              {{ comment.content }}
                            </div>
                          </div>

                          <button
                              class="reply-btn"
                              @click="toggleCommentReplies(index, comment)"
                          >
                            <v-icon
                            >{{
                                this.showReplyComments[index]
                                    ? 'mdi-chevron-down'
                                    : 'mdi-chevron-right'
                              }}
                            </v-icon>
                            <span v-if="comment.comments.length > 0">
                              {{ comment.comments.length }}
                              {{
                                comment.comments.length == 1
                                    ? 'réponse'
                                    : 'réponses'
                              }}
                            </span>
                            <span v-else> Répondre</span>
                          </button>
                          <div v-show="this.showReplyComments[index]">
                            <template v-if="comment.comments.length > 0">
                              <v-list-item
                                  v-for="(reply, replyIndex) in comment.comments"
                                  :key="replyIndex"
                                  class="reply-list"
                              >
                                <template v-slot:prepend>
                                  <v-avatar
                                      :image="getAvatar(reply.user.avatar)"
                                      class="avatar-image"
                                  ></v-avatar>
                                </template>
                                <template v-slot:append>
                                  <div class="text-center">
                                    <v-menu v-if="reply.user.id == this.user.id">
                                      <template v-slot:activator="{ props }">
                                        <v-btn class="options-btn" color="grey-lighten-1" icon="mdi-dots-horizontal"
                                               variant="text" v-bind="props"></v-btn>
                                      </template>
                                      <v-list class="comment-settings">
                                        <v-list-item v-for="(commentStatus, i) in getCommentSettings(reply)" :key="i">
                                          <v-list-item-title @click="handleSettingActions(commentStatus, reply)">
                                            <v-icon v-if="commentStatus.title === settingActions.edit"
                                                    class="mr-2 settings-icon">
                                              mdi-pencil
                                            </v-icon>
                                            {{
                                              commentStatus.title
                                            }}
                                          </v-list-item-title>

                                        </v-list-item>
                                      </v-list>

                                    </v-menu>
                                  </div>
                                </template>
                                <v-list-item-content>
                                  <div v-if="reply.isEditing" style="padding-left: 20px">
                                    <v-form @submit.prevent="saveEditComment(reply)">
                                      <v-text-field
                                          v-model="reply.content"
                                          class="edit-comment-input"
                                          color="#05a9f4"
                                          multi-line
                                          placeholder="Modifier le commentaire"
                                          variant="outlined"
                                      ></v-text-field>
                                      <div class="pa-4 d-flex justify-end">
                                        <v-btn class="me-2 cancel-btn" variant="flat"
                                               @click="comment.isEditing = false">Annuler
                                        </v-btn>
                                        <v-btn
                                            :class="!reply.content || reply.content.trim() === '' ? 'vdt-btn' : 'vdt-enabled-btn'"
                                            :disabled="!reply.content || reply.content.trim() === ''"
                                            type="submit" variant="text">
                                          Valider
                                        </v-btn>
                                      </div>
                                    </v-form>
                                  </div>
                                  <div v-else style="padding-left: 20px">
                                    <div style="font: normal normal bold 14px/19px Roboto; color: #494949;">
                                      {{ reply.user.username }}
                                    </div>
                                    <div style="font: normal normal normal 12px/16px Roboto; color: #707070;">
                                      {{ $filters.formatDateDMYHMS(reply.created_at, true) }}
                                    </div>
                                    <div class="comment">
                                      {{ reply.content }}
                                    </div>
                                  </div>

                                </v-list-item-content>

                              </v-list-item>
                              <v-form
                                  v-form
                                  class="comment-form"
                                  @submit.prevent="
                                  saveComment(event, comment.id, index)
                                "
                              >
                                <v-text-field
                                    v-model="this.commentReplayForm[index]"
                                    append-inner-icon="mdi-at"
                                    class="comment-reply-input"
                                    color="#05a9f4"
                                    multi-line
                                    placeholder="Rédiger une réponse"
                                    variant="outlined"
                                ></v-text-field>
                                <div class="pa-4 d-flex justify-end">
                                  <v-btn
                                      class="me-2 cancel-btn"
                                      variant="flat"
                                      @click="cancelComment"
                                  >Annuler
                                  </v-btn
                                  >
                                  <v-btn
                                      :class="
                                      !this.commentReplayForm[index] ||
                                      this.commentReplayForm[index].trim() ===
                                        ''
                                        ? 'vdt-btn'
                                        : 'vdt-enabled-btn'
                                    "
                                      :disabled="
                                      !this.commentReplayForm[index] ||
                                      this.commentReplayForm[index].trim() ===
                                        ''
                                    "
                                      type="submit"
                                      variant="text"
                                  >Valider
                                  </v-btn>
                                </div>
                              </v-form>

                            </template>
                            <template v-else>
                              <v-form v-form class="comment-form" @submit.prevent="
                                saveComment(event, comment.id, index)
                                ">
                                <v-text-field v-model="this.commentReplayForm[index]" append-inner-icon="mdi-at"
                                              class="comment-reply-input" color="#05a9f4" multi-line
                                              placeholder="Rédiger une réponse" variant="outlined"></v-text-field>
                                <div v-if="this.showAlert" class="alert alert-danger mt-2">
                                  Le champ de commentaire est obligatoire.
                                </div>
                                <div class="pa-4 d-flex justify-end">
                                  <v-btn
                                      class="me-2 cancel-btn"
                                      variant="flat"
                                      @click="cancelComment"
                                  >
                                    Annuler
                                  </v-btn>

                                  <v-btn
                                      :class="
                                      !this.commentReplayForm[index] ||
                                      this.commentReplayForm[index].trim() ===
                                        ''
                                        ? 'vdt-btn'
                                        : 'vdt-enabled-btn'
                                    "
                                      :disabled="
                                      !this.commentReplayForm[index] ||
                                      this.commentReplayForm[index].trim() ===
                                        ''
                                    "
                                      type="submit"
                                      variant="text"
                                  >
                                    Valider
                                  </v-btn>
                                </div>
                              </v-form>
                            </template>
                          </div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>

                <!--buttons on the botttom  start-->
                <div class="bottom-buttons pa-4 d-flex justify-space-between">
                  <div>
                    <v-btn
                        :class="{
                        'btn-untreated': activeButton !== 'UNTREATED',
                        'btn-active': activeButton === 'UNTREATED',
                      }"
                        class="me-2 btn-custom"
                        variant="flat"
                        @click="setActiveButton('UNTREATED')"
                    >
                      <img
                          :src="getPictogram('message-white')"
                          class="pictogram me-1"
                      />{{ this.untreatedComment }}
                      {{ untreatedTerm }}
                    </v-btn>
                    <v-btn
                        :class="{
                        'btn-treated': activeButton !== 'TREATED',
                        'btn-active': activeButton === 'TREATED',
                      }"
                        class="me-2 btn-custom"
                        type="submit"
                        variant="flat"
                        @click="setActiveButton('TREATED')"
                    >
                      <img
                          :src="getPictogram('message-check-white')"
                          class="pictogram me-1"
                      />{{ this.treatedComment }}
                      {{ treatedTerm }}
                    </v-btn>
                    <v-btn
                        :class="{
                        'btn-rejected': activeButton !== 'REJECTED',
                        'btn-active': activeButton === 'REJECTED',
                      }"
                        class="me-2 btn-custom"
                        type="submit"
                        variant="flat"
                        @click="setActiveButton('REJECTED')"
                    >
                      <img
                          :src="getPictogram('message-xmark-white')"
                          class="pictogram me-1"
                      />{{ this.rejectedComment }} {{ rejectedTerm }}
                    </v-btn>
                  </div>
                  <div>
                    <button
                        class="me-2 btn-filter-off"
                        type="submit"
                        variant="flat"
                        @click="setActiveButton()"
                    >
                      <v-icon>mdi-filter-remove-outline</v-icon>
                    </button>
                  </div>
                </div>
                <!--buttons on the botttom  end-->
              </v-navigation-drawer>
            </v-col>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Footer section -->
    </v-card>
  </v-dialog>

  <template>
    <v-dialog
        v-model="commentSubmitPermissionPopup"
        content-class="filterDialog"
        hide-overlay
        width="400px"
    >
      <v-card
          style="
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          overflow: visible;
        "
      >
        <div class="dialog-large-circle">
          <div
              :style="{
              backgroundColor: this.dialogtPermissionPopupContent.color,
            }"
              class="dialog-circle"
          >
            <span>i</span>
          </div>
        </div>

        <h3
            :style="{
            color: this.dialogtPermissionPopupContent.color,
            marginTop: '60px',
          }"
            class="permision-label"
        >
          {{ this.dialogtPermissionPopupContent.title }}
        </h3>
        <div class="mt-4"></div>
        <!-- Three rows of content -->
        <div class="mt-4"></div>
        <h3 class="filter-label">
          {{ this.dialogtPermissionPopupContent.confirmText }}
        </h3>
        <div class="mt-4"></div>
        <div class="d-flex justify-center">
          <v-btn
              v-if="this.dialogtPermissionPopupContent.actionType != 'NotAuthExit' "
              :style="{
              color: dialogtPermissionPopupContent.btnColor,
              backgroundColor: dialogtPermissionPopupContent.color,
            }"
              class="filter-remove-btn mx-3"
              variant="text"
              @click="
              sendReviewMockup(
                selectedTask,
                this.dialogtPermissionPopupContent.actionType
              )
            "
          >
            <v-icon> mdi-send-outline</v-icon>
            Envoyer
          </v-btn>
          <v-btn
              class="filter-remove-btn"
              variant="text"
              @click="commentSubmitPermissionPopup = false"
          >
            <v-icon> mdi-close</v-icon>
            Annuler
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </template>
</template>

<script>
import html2pdf from 'html2pdf.js';
import {useUserStore} from '../../store/userStore';
import {fileService as fileAPI, STATUS_IN_REVISION, STATUS_TO_CORRECT,} from '../../services/file.service';
import {
  ADD_COMMENT_MSG,
  commentService as commentAPI,
  LUNCH_FIX_MSG,
  REJECTED,
  TREATED,
  UNTREATED,
  VALIDATE_REVISION_MSG,
} from '../../services/comment.service';
import {settingActions} from '../../enums/settingActions';
import {useTaskStore} from '../../store/taskStore';
import {useDialogStateStore} from '../../store/DialogStateStore';
import {BASE_URL, BE_BASE_URL} from '../../config/config';
import translate from '../../utils/translate.js';

const fileService = fileAPI();
const commentService = commentAPI();

export default {
  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
    project: {type: Number}, // Default empty object to prevent errors if prop is not provided
    taskId: {type: Number},
    taskTitle: {type: String},
  },
  created() {
    const userStore = useUserStore();
    this.user = userStore.user;
    this.fetchStatusComments();
    this.fetchProjectDetails();
    this.getFilesByTaskId(this.taskId);
  },
  setup() {
    const taskStore = useTaskStore();
    const dialogStateStore = useDialogStateStore();
    return {taskStore, dialogStateStore};
  },
  data() {
    return {
      validerDisabled: false,
      selectedRevNP: '',
      currentReviewNP: 0,
      currentModelReviewN: 0,
      currentModelReviewP: 0,
      imageHeight: 0,
      imageWidth: 0,
      dialog: this.dialog,
      settingActions: settingActions, // Expose the enum to the template
      projectDetails: null,
      selectedTask: this.taskId,
      selectedTaskTitle: '',
      tasksList: [],
      filesListToReview: [],
      modelLargeView: false,
      isCommentPopupOpen: false,
      currentModel: 1,
      pageSize: 1, // Number of images per page
      itemsEye: [
        {title: 'Visible par le client', icon: 'mdi-eye-outline'},
        {title: 'Non-visible par le client', icon: 'mdi-eye-off-outline'},
      ], // items eye
      itemsDownload: [{title: 'Fichier PDF'}, {title: 'Image JPG'}],
      comments: [],
      commentForm: {
        content: '',
        private: Boolean,
        status: Number, // You can replace 'any' with the appropriate type
        user: Number,
        parent: Number | null,
        revision: Number,
      },
      commentReplayForm: {},
      commentPin: {
        CoordinateX: Number | null,
        CoordinateY: Number | null,
      },
      commentStatusList: [], // comment
      //  showReplies: [], // var to  show replies's  comment or not
      imagePined: false, // var to test if image is pinned or not (icon pin )
      commentResponse: [], // Store user's reply for each comment
      //TODO::start zoom var
      zoomLevels: [50, 75, 100, 125, 150, 200],
      selectedZoomLevel: 100,
      zoomLevel: 1.0,
      transformX: 0,
      transformY: 0,
      isDragging: false,
      startX: 0,
      startY: 0,
      scrollLeft: 0,
      scrollTop: 0,
      container: null,
      clickX: 0,
      clickY: 0,
      lastZoomLevel: 1,
      clickTimeout: null,
      isLongClick: false,
      //TODO::end zoom var
      filterDialog: false, // filter comment dialog
      filterOptionsAuthor: ['Laurent Ipsun', 'Vincent Wichy'],
      filterOptionsDate: ['Hier', '7 derniers jours', 'Antérieur'],
      filterOptionsComments: ['Refusés', 'Non-traités', 'Traités'],
      selectedFilters: [],
      revisionsList: [], // rev list by file
      selectedFile: null, // the selected file of rev
      paginate: false,
      newRevison: false,
      fileList: [],
      revisionLabel: '',
      revisionDate: '',
      taskLabel: '',
      fileListPaginated: [], // file list paginated
      paginatedRevisionsShownByClient: [], // contain the revisons reviewed by client
      commentSubmitPermissionPopup: false, // show comment push popup
      selectedRevisionVisibility: false, // rev visibility
      selectedRevisionUpdatedDate: '', // rev updated date
      dialogtPermissionPopupContent: {
        actionType: '',
        title: '',
        color: '',
        confirmText: '',
        btnColor: '',
      },
      currentModelReview: 0,
      currentModelReviewPrev: 0,
      untreatedComment: 0,
      treatedComment: 0,
      rejectedComment: 0,
      activeButton: '',
      views: [
        {
          name: 'desktop',
          required: 'desktop_view_required',
          icon: 'mdi-monitor',
        },
        {
          name: 'laptop',
          required: 'laptop_view_required',
          icon: 'mdi-laptop',
        },
        {
          name: 'tablet',
          required: 'tablet_view_required',
          icon: 'mdi-tablet',
        },
        {
          name: 'mobile',
          required: 'mobile_view_required',
          icon: 'mdi-cellphone',
        },
        {name: 'other', required: 'other_view_required', icon: 'mdi-monitor'},
      ],
      showReplyComments: [],
      highlightedComments: {},
      targetCommentId: null,
      highlightedCommentId: null,
      highlightedPinId: null,
      showAlert: false,
      other_item: false,
      authrizeCloseDialog: false,
      allViewed: false,
    };
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateImageDimensions);
  },
  computed: {
    imageStyle() {
      return {
        transform: this.isDragging
            ? `scale(${this.zoomLevel}) translate(${this.transformX}%, ${this.transformY}px)`
            : this.imagePined
                ? `scale(1)`
                : `scale(${this.zoomLevel})`,
        cursor: this.isDragging ? 'grabbing' : '',
        transition: this.isDragging ? 'transform 0.1s ease' : '',
      };
    },
    // imageStyle() {
    //   console.log('drag',this.isDragging);
    //   console.log('zoooom-------------',this.zoomLevel);
    //   return {
    //   transform: this.isDragging ? `scale(${this.zoomLevel}) translate(${this.transformX}%, ${this.transformY}px)` : `scale(${this.zoomLevel})`,
    //   cursor: this.isDragging ? 'grabbing' : '',
    //   transition: this.isDragging ? 'transform 0.1s ease' : '',
    // };
    // },
    translate() {
      return translate;
    },
    sortedComments() {
      return this.comments.slice().sort((a, b) => b.id - a.id);
    },
    enableSendCommentButton() {
      // Check if isCommentAvailable is true in any of the revisions
      return this.revisionsList.isCommentAvailable;
    },
    enableValidateButton() {
      // Checks if no file has a revision with isCommentAvailable set to true
      return !this.enableSendCommentButton;
    },
    isFirstItem() {
      // Checks if the current model review is the first item
      return this.currentModelReview === 1;
    },
    commentsExist() {
      // Checks if there are any comments in the file list to review
      if (!this.fileListToReview || this.fileListToReview.length === 0) {
        return false;
      }

      return this.fileListToReview.some((file) => {
        // Checks if the file revisions contain any comments
        if (!file.revisions || file.revisions.length === 0) {
          return false;
        }

        return file.revisions.some((revision) => this.comments.length > 0);
      });
    },
    totalModels() {
      // Calculates the total number of models based on the filtered file list and page size
      const filteredList = this.fileListPaginated.filter((file) =>
          file.revisions.some(
              (revision) => revision.status.id === this.statusMaketSelected
          )
      );
      return filteredList.length > 0
          ? Math.ceil(filteredList.length / this.pageSize)
          : 0;
    },
    paginatedModels() {
      // Paginates the models based on the current model and page size
      if (this.fileList) {
        let fileListPaginated = [...this.fileList];
        fileListPaginated = fileListPaginated.filter((file) =>
            file.revisions.some(
                (revision) => revision.status.id === this.statusMaketSelected
            )
        );

        if (this.selectedFile && !this.newRevison) {
          const data = this.selectedFile;
          const index = fileListPaginated.findIndex(
              (file) => file.title === data.title
          );
          this.currentModel = index + 1;
          this.selectedFile = null;
        }

        if (this.newRevison && this.selectedFile) {
          const data = this.selectedFile;
          const index = fileListPaginated.findIndex(
              (file) => file.title === data.title
          );
          fileListPaginated[index] = data;
          this.currentModel = index + 1;
        }

        const startIndex = (this.currentModel - 1) * this.pageSize;
        const endIndex = startIndex + this.pageSize;
        return fileListPaginated.slice(startIndex, endIndex);
      }
    },
    tasksList() {
      // Filters the tasks list to include only tasks with non-zero file count
      if (this.projectDetails) {
        return this.projectDetails.tasks
        .filter((task) => task.files_nb !== 0)
        .map((task) => ({
          id: task.id,
          title: task.title,
        }));
      }
    },
    fileTypeList() {
      // Maps the file list to a simplified file type list
      if (this.fileList) {
        return this.fileList.map((file) => ({
          id: file.id,
          view: file.view,
          updated_at: file.updated_at,
        }));
      }
    },
    filesListToReview() {
      // Filters the file list to include files with revisions that have specific status
      if (this.fileList) {
        const allFormatsToView = this.fileList
        .filter((item) =>
            item.revisions.some(
                (revision) =>
                    revision.status.id === STATUS_IN_REVISION &&
                    revision.status.id === this.statusMaketSelected
            )
        )
        .map((file) => ({
          id: file.id,
          view: file.view,
          updated_at: file.updated_at,
        }));
        this.dialogStateStore.setTotalFormats(allFormatsToView.length);
        return allFormatsToView;
      }
    },
    /*    allViewed() {
          // Checks if all files to review have been viewed
          if (this.filesListToReview) {
            return this.filesListToReview
            .filter((item) =>
                item.revisions.some((revision) => revision.status.id === 4)
            )
            .every((file) => file.viewed);
          }
        },*/
    getCommentSettings() {
      return (comment) => {
        if (this.commentStatusList) {
          const commentStatusList = [...this.commentStatusList];
          const modifiedItems = !this.user.is_client && comment.isParent ? commentStatusList.splice(1, 2).map((item) => {
            if (item.id === TREATED) {
              item.title = this.settingActions.treat;
            }
            if (item.id === REJECTED) {
              item.title = this.settingActions.refuse;
            }
            return item;
          }) : [];

          return comment.user.id === this.user.id
              ? [{title: this.settingActions.edit}, ...modifiedItems]
              : modifiedItems;
        }
        return [];
      };
    },
    commentReplySettings() {
      // Returns settings for comment replies
      if (this.commentStatusList) {
        return [
          {id: 1, title: settingActions.edit},
          {id: 2, title: settingActions.delete},
        ];
      }
    },
    isFilesToReviewExist() {
      // Checks if there are any files to review with a specific status
      const filesToReview = this.fileList.filter((file) =>
          file.revisions.some((rev) => rev.status.id === 4)
      );
      return filesToReview.length > 0;
    },
    untreatedTerm() {
      return this.untreatedComment <= 1 ? 'Non-traité' : 'Non-traités';
    },
    treatedTerm() {
      return this.treatedComment <= 1 ? 'Traité' : 'Traités';
    },
    rejectedTerm() {
      return this.rejectedComment <= 1 ? 'Refusé' : 'Refusés';
    },
    watch: {
      zoomLevel(newZoomLevel) {
        this.selectedZoomLevel = (newZoomLevel * 100).toFixed(0).toString();
      },
      sortedComments(newVal) {
        if (Array.isArray(newVal)) {
          this.comments = newVal.map(comment => ({
            ...comment,
            isEditing: false,
          }));
        }
      }
    },
    UNTREATED() {
      return UNTREATED;
    },
    TREATED() {
      return TREATED;
    },
    REJECTED() {
      return REJECTED;
    },
    add_comment_msg() {
      return ADD_COMMENT_MSG;
    },
    revision_validate_msg() {
      return VALIDATE_REVISION_MSG;
    },
    lunch_fix_msg() {
      return LUNCH_FIX_MSG;
    },
    status_to_correct() {
      return STATUS_TO_CORRECT;
    },
    status_in_revision() {
      return STATUS_IN_REVISION;
    },
  },
  methods: {
    setActiveButton(buttonType = '') {
      this.activeButton = buttonType;
      let status;
      if (buttonType === 'UNTREATED') {
        status = this.UNTREATED;
      } else if (buttonType === 'TREATED') {
        status = this.TREATED;
      } else if (buttonType === 'REJECTED') {
        status = this.REJECTED;
      } else {
        this.activeButton = '';
      }
      this.fetchComments(this.selectedRev.id, status);
    },

    updateImageDimensions() {
      const img = document.getElementById('image-to-download');
      if (img) {
        this.imageHeight = img.clientHeight;
        this.imageWidth = img.clientWidth;
      }
    },
    /**
     * Returns the full URL of the user's avatar.
     * @param {String} item - The filename of the avatar.
     * @returns {String} The full URL of the avatar.
     */
    getAvatar(item) {
      return `${BE_BASE_URL}uploads/users/avatars/${item}`;
    },
    getPictogram(item) {
      return `${BASE_URL}assets/images/${item}.svg`;
    },
    /**
     * Handles the change in paginated models. If there are paginated models,
     * it fetches revisions for the first model.
     */
    handlePaginatedModelsChange() {
      if (this.fileListPaginated.length && this.paginate) {
        const fileId = this.paginatedModels[0].id;
        this.getRevisionsByFileId(fileId);
        this.paginate = false;
      }
    },

    /**
     * Fetches project details from the server.
     * @async
     */
    async fetchProjectDetails() {
      try {
        const response = await this.axios.post(`project/${this.project}`, {
          isArchived: 0,
        });
        this.projectDetails = response.data;
        this.isLoadingData = false;
      } catch (error) {
        console.error('Error fetching project list data:', error);
      }
    },

    /**
     * Returns the full URL of an image.
     * @param {String} item - The relative path of the image.
     * @returns {String} The full URL of the image.
     */
    getImageSource(item) {
      this.$nextTick(() => {
        this.updateImageDimensions();
        window.addEventListener('resize', this.updateImageDimensions);
      });
      return `${BE_BASE_URL}${item}`;
    },

    /**
     * Returns the icon name based on the device type.
     * @param {String} type - The type of the device.
     * @returns {String} The icon name.
     */
    getViewIcon(type) {
      const icons = {
        tablet: 'mdi-tablet',
        mobile: 'mdi-cellphone',
        desktop: 'mdi-monitor',
        laptop: 'mdi-laptop',
        other: 'mdi-monitor',
      };
      return icons[type] || 'mdi-help';
    },

    /**
     * Returns the view text based on the device type.
     * @param {String} type - The type of the device.
     * @returns {String} The view text.
     */
    getViewText(type) {
      const texts = {
        tablet: 'Tablette',
        mobile: 'Mobile',
        desktop: 'Ecran Large',
        laptop: 'Laptop',
        other: 'Autre',
      };
      return texts[type] || 'Unknown';
    },


    /**
     * Downloads the current view as a PDF file.
     */
    async downloadPdf() {
      const element = document.getElementById('image-to-download');
      const imageUrl = element.src; // Get the original image URL
      const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);

      try {
        // Fetch the image and get the Blob URL
        const blobUrl = await fileService.fetchImageAsBlobUrl(imageUrl);

        // Create a new Image element using the Blob URL
        const img = new Image();
        img.src = blobUrl;

        // Wait for the image to load fully before proceeding
        img.onload = () => {
          // Create a temporary container for the image
          const tempDiv = document.createElement('div');
          tempDiv.style.width = img.width + 'px'; // Set exact width of the image
          tempDiv.style.height = img.height + 'px'; // Set exact height of the image
          tempDiv.appendChild(img);

          // Determine orientation based on image dimensions
          const orientation = img.width > img.height ? 'landscape' : 'portrait';

          // Append tempDiv to the body to ensure rendering before conversion
          document.body.appendChild(tempDiv);

          // Generate the PDF from the temporary container
          html2pdf()
          .from(tempDiv)
          .set({
            margin: 0, // Ensure no margin to avoid extra pages
            filename: filename.replace(/\.[^/.]+$/, '') + '.pdf',
            html2canvas: {
              scale: window.devicePixelRatio, // Use the device's pixel ratio to adjust for zoom
              logging: true,
              useCORS: true, // Enable CORS for cross-origin images
            },
            jsPDF: {
              unit: 'px',
              format: [img.width, img.height], // Set PDF size to the image dimensions
              orientation: orientation, // Set dynamic orientation (landscape or portrait)
            },
          })
          .save()
          .then(() => {
            // Clean up the Blob URL and temporary div after PDF creation
            URL.revokeObjectURL(blobUrl);
            document.body.removeChild(tempDiv); // Remove the temporary div after PDF creation
          });
        };

        img.onerror = () => {
          console.error('Error loading the image from Blob.');
        };

      } catch (error) {
        console.error('Error fetching the image:', error);
      }
    },

    /**
     * Downloads the current view as a JPEG image.
     */
    async downloadJPG() {
      const element = document.getElementById('image-to-download');
      const imageUrl = element.src; // Get the original image URL
      const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);

      try {
        // Fetch the image and get the Blob URL
        const blobUrl = await fileService.fetchImageAsBlobUrl(imageUrl);

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = filename.replace(/\.[^/.]+$/, '') + '.jpg'; // Save as .jpg
        document.body.appendChild(link); // Append the link to the document
        link.click(); // Trigger the download
        document.body.removeChild(link); // Clean up the link element

        // Revoke the object URL to free up memory
        URL.revokeObjectURL(blobUrl);

      } catch (error) {
        console.error('Error downloading the image:', error);
      }
    },

    /**
     * Fetches the file by revision ID and updates the relevant data.
     * @param {Object} revision - The revision object.
     * @param {Number} index - The index of the revision.
     * @async
     */
    async getFileByRevisionId(revision, index) {
      try {
        this.initZoomLevel();
        this.selectedRev = revision;
        this.revisionDate = this.selectedRev.updated_at;
        this.selectedRevisionVisibility = revision.visible;
        this.selectedRevisionUpdatedDate = revision.updated_at;
        this.selectedFile = revision.file;
        this.getRevisionLabel(index);
        await this.fetchComments(revision.id);
        this.newRevison = true;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * Fetches files by task ID and updates the relevant data.
     * @param {Number} taskId - The ID of the task.
     * @async
     */
    async getFilesByTaskId(taskId) {
      try {
        if (typeof taskId == 'string') {
          this.selectedFile = null;
          const files = await fileService.getFilesByTaskId(taskId);
          this.fileList = files;
          this.fileListPaginated = files;
          await this.getRevisionsByFileId(files[0].id);
          this.currentModel = 1;
          this.isLoadingData = false;
          this.selectedTask = taskId;
          this.taskLabel = this.selectedTaskTitle;
          this.taskStore.setSelectedTask(taskId);
        } else {
          this.selectedFile = null;
          const files = await fileService.getFilesByTaskId(taskId.raw.id);
          this.fileList = files;
          this.fileListPaginated = files;
          await this.getRevisionsByFileId(files[0].id);
          this.currentModel = 1;
          this.isLoadingData = false;
          this.selectedTask = taskId;
          this.taskLabel = taskId.raw.title;
          this.taskStore.setSelectedTask(taskId);
        }
      } catch (error) {
        this.fileList = [];
        console.error('error get file by task it ' + error.toString());
      }
    },

    /**
     * Fetches revisions by file ID and updates the relevant data.
     * @param {Number} fileId - The ID of the file.
     * @async
     */
    async getRevisionsByFileId(fileId) {
      try {
        const revisions = await fileService.getRevisionsByFileId(fileId);

        this.revisionsList = revisions;
        this.selectedTaskTitle = this.revisionsList.task_title;
        // Get the last revision
        const lastRevision =
            revisions.fileRevisions[revisions.fileRevisions.length - 1];
        this.revisionDate = lastRevision.updated_at;
        this.selectedRev = revisions.fileRevisions[0];
        this.getRevisionLabel(this.selectedRev.revisionNbr);
        this.selectedRevisionVisibility = this.selectedRev.visible;
        this.selectedRevisionUpdatedDate = this.selectedRev.updated_at;
        this.titleLargeModal();

        await this.fetchComments(this.selectedRev.id);
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * get the number revision that shown in the menu
     * @param number
     */
    getRevisionLabel(number) {
      this.revisionLabel = `Version #${number}`;
    },

    /**
     * Displays revisions based on the selected view type.
     * @param {Number} fileId - The ID of the file.
     * @param {Boolean} show - Whether to show the revisions.
     * @async
     */
    async showRevisionsBySelectedViewType(fileId, show) {
      try {
        await this.getRevisionsByFileId(fileId);
        this.statusMaketSelected =
            this.revisionsList.fileRevisions[0].status.id;

        if (!show) {
          const index = this.fileTypeList.findIndex(
              (file) => file.id === fileId
          );
          this.currentModel = index + 1;
          this.selectedFile = this.revisionsList.fileRevisions[0].file;
          this.selectedRev = this.revisionsList.fileRevisions[0];
          this.selectedRevisionVisibility = this.selectedRev.visible;
          this.selectedRevisionUpdatedDate = this.selectedRev.updated_at;

          if (
              !this.paginatedRevisionsShownByClient.includes(this.selectedRev.id)
          ) {
            this.paginatedRevisionsShownByClient.push(this.selectedRev.id);
          }

          const file = this.filesListToReview.find(
              (file) => file.id === fileId
          );
          const indexReview = this.filesListToReview.findIndex(
              (file) => file.id === fileId
          );
          if (file) {
            file.viewed = true;
            this.currentModelReview = indexReview + 1;
          }

          await this.fetchComments(this.selectedRev.id);
        } else {
          if (!this.user.is_client) {
            this.setModelForAdmin();
          } else {
            this.setModelForClient(fileId);
          }
        }
        this.titleLargeModal();
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * Sets the model view for the admin user.
     */
    setModelForAdmin() {
      this.selectedFile = this.revisionsList.fileRevisions[0].file;
      this.selectedRev = this.revisionsList.fileRevisions[0];
      this.selectedRevisionVisibility = this.selectedRev.visible;
      this.selectedRevisionUpdatedDate = this.selectedRev.updated_at;
      this.fetchComments(this.selectedRev.id);
      this.modelLargeView = true;
    },

    /**
     * Sets the model view for the client user.
     * @param {Number} fileId - The ID of the file.
     */
    setModelForClient(fileId) {
      const visibleRevision = this.revisionsList.fileRevisions.find(
          (revision) => revision.visible
      );
      if (visibleRevision) {
        this.selectedFile = visibleRevision.file;
        this.selectedRev = visibleRevision;
        this.selectedRevisionVisibility = this.selectedRev.visible;
        this.selectedRevisionUpdatedDate = this.selectedRev.updated_at;

        if (
            !this.paginatedRevisionsShownByClient.includes(this.selectedRev.id)
        ) {
          this.paginatedRevisionsShownByClient.push(this.selectedRev.id);
        }

        const file = this.fileList.find((file) => file.id === fileId);
        const indexReview = this.fileList.findIndex(
            (file) => file.id === fileId
        );
        if (file) {
          file.viewed = true;
          this.currentModelReview = indexReview + 1;
        }

        this.fetchComments(this.selectedRev.id);
        this.modelLargeView = true;
        this.dialogStateStore.markFormatAsViewed(this.currentModel);
      } else {
        this.resetModelView();
      }
    },

    /**
     * Resets the model view.
     */
    resetModelView() {
      this.selectedFile = null;
      this.selectedRev = null;
      this.modelLargeView = false;
    },

    /**
     * Updates the visibility of the revision by the client.
     * @param {Number} revId - The ID of the revision.
     * @param {Number} index - The index to determine visibility status.
     * @async
     */
    async updateVisibilityByClient(revId, index) {
      try {
        const status = index === 0;
        const revision = await fileService.updateRevisionVisibility(
            revId,
            status
        );
        this.selectedRevisionVisibility = revision.visible;
        this.selectedRevisionUpdatedDate = this.selectedRev.updated_at;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * Closes the dialog and navigates to the project details.
     */
    closeDialog() {
      if (this.user.is_client && this.selectedRev.status.id == 4 && this.modelLargeView) {
        console.log('allFormatsViewed = ', this.dialogStateStore.allFormatsViewed)
        this.commentSubmitPermissionPopup = true;
        this.allViewed = this.dialogStateStore.allFormatsViewed;
        this.authrizeCloseDialog = true
        this.setDialogPermissionPopupContent(this.allViewed, this.enableSendCommentButton)
      } else {
        this.dialog = false;

        this.$router.push({
          name: 'ProjectDetails',
          params: {id: this.projectDetails.id},
        });
        this.modelLargeView = false;
      }
    },
    setDialogPermissionPopupContent(allView, enableSendCommentButton) {
      if (allView && enableSendCommentButton) {
        this.dialogtPermissionPopupContent = {
          actionType: 'sendComments',
          title: 'Êtes-vous sûr de vouloir envoyer vos commentaires ?',
          color: '#C90D0F !important',
          confirmText: 'Une fois vos demandes envoyées, vous ne pourrez plus faire de nouvelle demande de correction avant la prochaine itération.',
          btnColor: '#FFF',
        };
      } else if (allView && !enableSendCommentButton) {
        this.dialogtPermissionPopupContent = {
          actionType: 'validateMockups',
          title: 'Êtes-vous sûr de vouloir valider les maquettes ?',
          color: '#0F8000 !important',
          confirmText: 'Une fois les maquettes validées, vous ne pourrez plus faire de nouvelles demandes de correction.',
          btnColor: '#FFF',
        };
      } else { // Not all viewed
        this.dialogtPermissionPopupContent = {
          actionType: 'NotAuthExit',
          title: 'Veuillez consulter toutes les révisions en cours.',
          color: '#C90D0F !important',
          confirmText: 'Il y a d’autres révisions à consulter.',
          btnColor: '#FFF',
        };
      }
    },
    /**
     * Shows the model view based on the file ID and show flag.
     * @param {Number} fileId - The ID of the file.
     * @param {Boolean} show - Whether to show the model view.
     */
    showModel(fileId, show) {
      //selected first maque whith status a revision to check in icon
      this.selectedRevNP = this.fileList.find((file) =>
          file.revisions.some((revision) => revision.status.id === 4)
      );
      const isclient = this.user.is_client;
      if (isclient) {
        const file = this.fileList[this.fileList.length - 1];
        if (
            file.listEnabledMaquetsIds &&
            file.listEnabledMaquetsIds.includes(fileId)
        ) {
          this.showRevisionsBySelectedViewType(fileId, show);
        }
      } else {
        this.showRevisionsBySelectedViewType(fileId, show);
      }
    },
    /**
     * Initializes the zoom level and transform coordinates.
     *
     * This function sets the initial zoom level to 1.0 and resets the transformation
     * coordinates (transformX and transformY) to 0. It is used to reset the view
     * to its default state.
     */
    initZoomLevel() {
      this.zoomLevel = 1.0;
      this.transformX = 0;
      this.transformY = 0;
    },
    /**
     * Zooms in the image by increasing the zoom level.
     * This function also cancels any active comment and adjusts the scroll position to keep the zoom focused on the current area.
     * If the zoom level reaches 100%, it resets the zoom level.
     */
    zoomIn() {
      this.imagePined = false;
      this.cancelComment();
      this.adjustZoom(0.2, 100);
    },

    /**
     * Zooms out the image by decreasing the zoom level.
     * This function adjusts the scroll position to keep the zoom focused on the current area.
     * If the zoom level reaches 100%, it resets the zoom level.
     */
    zoomOut() {
      this.imagePined = false;
      this.adjustZoom(-0.2, 100);
    },

    /**
     * Adjusts the zoom level of the image based on the provided delta.
     * Updates the image transformations and ensures the zoom level remains within the specified limits.
     * If the zoom level equals the maximum zoom percentage, it resets the zoom level.
     *
     * @param {number} deltaZoom - The amount by which to adjust the zoom level (positive to zoom in, negative to zoom out).
     * @param {number} maxZoomPercentage - The maximum zoom percentage at which the zoom level should be reset.
     */
    adjustZoom(deltaZoom, maxZoomPercentage) {
      this.lastZoomLevel = this.zoomLevel;
      this.zoomLevel += deltaZoom;
      this.transformX = this.zoomLevel * 10;
      this.transformY += deltaZoom > 0 ? 600 : -100; // Adjust Y based on zoom direction

      const zoomPercentage = Math.round(this.zoomLevel * 100);
      if (zoomPercentage === maxZoomPercentage) {
        this.initZoomLevel();
      }

      this.adjustScrollPosition();
    },

    /**
     * Starts dragging the image when the mouse button is pressed.
     * Initializes the starting positions and adds event listeners for mouse movement and mouse release.
     */
    startDragging(event) {
      this.container = document.getElementById('element-to-convert');
      this.isDragging = true;
      this.startX = event.pageX - this.container.offsetLeft;
      this.startY = event.pageY - this.container.offsetTop;
      this.scrollLeft = this.container.scrollLeft;
      this.scrollTop = this.container.scrollTop;
      document.addEventListener('mousemove', this.onDragging);
      document.addEventListener('mouseup', this.stopDragging);
    },

    /**
     * Handles the dragging of the image as the mouse moves.
     * Updates the scroll positions based on the mouse movement.
     */
    onDragging(event) {
      this.container = document.getElementById('element-to-convert');
      if (!this.isDragging) return;
      event.preventDefault();
      const x = event.pageX - this.startX;
      const y = event.pageY - this.startY;
      this.container.scrollLeft = this.scrollLeft - x;
      this.container.scrollTop = this.scrollTop - y;
    },

    /**
     * Stops dragging the image when the mouse button is released.
     * Removes the event listeners for mouse movement and mouse release.
     */
    stopDragging() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.onDragging);
      document.removeEventListener('mouseup', this.stopDragging);
      clearTimeout(this.clickTimeout);
    },

    /**
     * Sets the position of the click relative to the image container.
     * This position is used to adjust the scroll position when zooming.
     */
    setClickPosition(event) {
      this.container = document.getElementById('element-to-convert');
      if (!this.container) {
        console.error('Container ref is not defined');
        return;
      }

      this.clickX =
          event.clientX - this.container.offsetLeft + this.container.scrollLeft;
      this.clickY =
          event.clientY - this.container.offsetTop + this.container.scrollTop;
    },

    /**
     * Adjusts the scroll position to center the zoom on the clicked point.
     * Uses the ratio of the new zoom level to the previous zoom level to calculate the new scroll positions.
     */
    adjustScrollPosition() {
      this.container = document.getElementById('element-to-convert');
      const zoomRatio = this.zoomLevel / this.lastZoomLevel;

      const newScrollLeft =
          this.clickX * zoomRatio - this.container.offsetWidth / 2;
      const newScrollTop =
          this.clickY * zoomRatio - this.container.offsetHeight / 2;

      this.container.scrollLeft = newScrollLeft;
      this.container.scrollTop = newScrollTop;
    },
    setValiderDisabled(status) {
      this.validerDisabled = status;
    },

    /**
     * Handles the mouse down event.
     * Sets the click position and starts a timeout to differentiate between a click and a long click.
     * If the mouse is held down for 500ms, starts dragging the image.
     */
    handleMouseDown(event) {
      this.setClickPosition(event);
      this.clickTimeout = setTimeout(() => {
        this.isLongClick = true;
        this.startDragging(event);
      }, 500); // 500ms for a long click
    },

    /**
     * Handles the mouse up event.
     * Clears the click timeout and stops dragging if it was a long click.
     * If it was a short click, zooms in on the clicked point.
     */
    handleMouseUp(event) {
      clearTimeout(this.clickTimeout);
      if (this.isLongClick) {
        this.stopDragging();
        this.isLongClick = false;
      } else {
        this.setClickPosition(event); // Ensure click position is set
        //this.zoomIn(); // Zoom in on click
      }
    },

    /**
     * Navigates to the previous model.
     */
    prevModel() {
      this.selectedFile = null;
      this.initZoomLevel();
      if (this.currentModel > 1) {
        this.currentModel--;
        this.paginate = true;
        this.setValiderDisabled(true);
        this.cancelComment();
        this.handlePaginatedModelsChange();
      }
    },

    /**
     * Navigates to the next model.
     */
    nextModel() {
      this.selectedFile = null;
      this.initZoomLevel();
      if (this.currentModel < this.totalModels) {
        this.currentModel++;
        this.paginate = true;
        this.setValiderDisabled(true);
        this.cancelComment();
        this.handlePaginatedModelsChange();
      }
    },

    /**
     * Navigates to the next model in review mode.
     */
    nextModelReviewMode() {
      this.initZoomLevel();
      this.cancelComment();
      this.setValiderDisabled(true);
      // Filter files for review if it's the first click
      if (this.currentReviewNP === 0) {
        this.filterFilesForReview();
      }

      // Proceed if there are more files to review
      if (
          this.currentReviewNP <
          this.filesListToReviewByMaketSelected.length - 1
      ) {
        this.currentReviewNP++; // Move to the next file to review
        this.selectedRevNP =
            this.filesListToReviewByMaketSelected[this.currentReviewNP];

        const file = this.selectedRevNP;
        if (file) {
          file.viewed = true; // Mark the file as viewed
        }

        this.currentModelReviewN++;
        this.currentModel++;
        this.dialogStateStore.markFormatAsViewed(this.currentModel)
        this.paginate = true; // Set paginate to true
        this.handlePaginatedModelsChange(); // Handle the change in paginated models
      }
    },

    /**
     * Filters files for review based on specific criteria.
     */
    filterFilesForReview() {
      this.filesListToReviewByMaketSelected = this.fileList
      .filter(
          (item) =>
              item.revisions[0].status.id === STATUS_IN_REVISION &&
              item.revisions[0].status.id === this.statusMaketSelected
      )
      .map((file) => ({
        id: file.id,
        view: file.view,
        updated_at: file.updated_at,
      }));
    },

    /**
     * Navigates to the previous model in review mode.
     */
    prevModelReviewMode() {
      this.initZoomLevel();
      // Proceed if not at the first file
      if (this.currentReviewNP > 0) {
        this.currentReviewNP--; // Move to the previous file to review
        this.selectedRevNP =
            this.filesListToReviewByMaketSelected[this.currentReviewNP];

        const file = this.selectedRevNP;
        if (file) {
          file.viewed = true; // Mark the file as viewed
        }

        this.currentModelReviewN--;
        this.currentModel--;
        this.paginate = true; // Set paginate to true
        this.handlePaginatedModelsChange(); // Handle the change in paginated models
      }
    },
    /**
     * Toggles the application of the filter style.
     */
    applyFilter() {
      this.initZoomLevel();
      //this.setValiderDisabled(false);
      this.imagePined = !this.imagePined;
      this.zoomLevel = 1.0;
    },

    /**
     * referesh revision list api to get iscomment new value.
     */
    async reloadData(file) {
      try {
        await this.getRevisionsByFileId(file);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    },

    /**
     * Saves a comment with an optional parent comment.
     * @param {Number|null} parent - The ID of the parent comment or null if it is a root comment.
     * @async
     */
    async saveComment(event, parent = null, index = null) {
      // If content is a getter function
      const content =
          parent == null
              ? this.commentForm.content
              : this.commentReplayForm[index];
      if (!content || content.trim() === "") {
        this.showAlert = true;
        console.error('Commentaire ne doit pas être vide');
        return;
      }
      const {CoordinateX, CoordinateY} = this.commentPin;
      const internalComment = !this.user.is_client;

      const newComment = {
        content,
        private: internalComment,
        status: 1,
        user: this.user.id,
        parent,
        revision: this.selectedRev.id,
      };

      const newPin = {CoordinateX, CoordinateY};

      try {
        await commentService.createComment(newComment, newPin);
        await this.fetchComments(this.selectedRev.id);
        this.resetCommentForm();
        //TODO:: re call API to get is there comment true
        await this.reloadData(this.selectedRev.file.id);
        this.taskStore.setSelectedTask(this.selectedTask);
        this.taskStore.displayAlertGraphicDesignerButton(
            this.taskStore.selectedTask
        );
      } catch (error) {
        console.error('Error saving comment:', error);
      }
    },

    /**
     * Resets the comment form to its initial state.
     */
    resetCommentForm() {
      this.commentForm = {};
      this.commentReplayForm = {};
      this.commentPin = {CoordinateX: 0, CoordinateY: 0};
      this.imagePined = false;
    },

    /**
     * Fetches comments by revision ID.
     * @param {Number} revId - The ID of the revision.
     * @param status
     * @async
     */
    async fetchComments(revId, status = 0) {
      try {
        const response = await commentService.getCommentsByRevisionId(
            revId,
            status
        );
        this.comments = response.comments;
        this.untreatedComment = response.total_comments.untreated_comments;
        this.treatedComment = response.total_comments.treated_comments;
        this.rejectedComment = response.total_comments.refused_comments;
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    },

    /**
     * Adds a comment to the GRC (Global Review Center) based on the task ID.
     * @param {Number} taskId - The ID of the task.
     * @param commentModalId
     * @async
     */
    async addCommentGRC(taskId, commentModalId) {
      try {
        const payload = this.user.is_client
            ? {private: 0, content: commentModalId}
            : {private: 1, content: commentModalId};
        await commentService.addCommentGrc(taskId, payload);
        this.taskStore.removeComment(taskId);
      } catch (error) {
        console.error('Error adding comment to GRC:', error);
      }
    },

    /**
     * Validates mockups for the specified task ID.
     * @param {Number} taskId - The ID of the task.
     * @param commentModelId
     * @async
     */
    async valideteMockups(taskId, commentModelId) {
      // //TODO:: prepaire list revisions ids
      const allRevisionIds = this.revisionsList.lastRevisionIds;
      // try {
      for (const revId of allRevisionIds) {
        await fileService.updateRevisionStatus(revId, 5);
      }
      await this.addCommentGRC(taskId, commentModelId);
      this.commentSubmitPermissionPopup = false;
      this.modelLargeView = false;
      // } catch (error) {
      //   console.error('Error validating mockups:', error);
      // }
    },

    /**
     * Opens the comment submit permission dialog with appropriate content based on the action type.
     * @param {Object} payload - The payload containing the action type.
     * @async
     */
    async openCommentSubmitPermission(payload) {
      const {actionType} = payload;
      if (actionType === 'sendComments') {
        this.dialogtPermissionPopupContent = {
          actionType,
          title: 'Etes-vous sûr de vouloir envoyer vos commentaires ?',
          color: '#C90D0F !important',
          confirmText:
              'Une fois vos demandes envoyées, vous ne pourrez plus faire de nouvelle demande de correction avant la prochaine itération.',
          btnColor: '#FFF',
        };
      } else {
        this.dialogtPermissionPopupContent = {
          actionType,
          title: 'Êtes-vous sûr de vouloir valider les maquettes ?',
          color: '#0F8000 !important',
          confirmText:
              'Une fois les maquettes validées, vous ne pourrez plus faire de nouvelles demandes de correction.',
          btnColor: '#FFF',
        };
      }
      this.commentSubmitPermissionPopup = true;
    },

    /**
     * Sends review mockups based on the tasks and action type.
     * @param task
     * @param {String} actionType - The type of action (sendComments or validateMockups).
     * @async
     */
    async sendReviewMockup(task, actionType) {
      if (task === undefined || task === null) {
        console.error(' task not defined ');
        return;
      }
      if (actionType === 'sendComments') {
        await this.sendComments(task, this.add_comment_msg);
      } else {
        await this.valideteMockups(task, this.revision_validate_msg);
      }

      if (task > 0) {
        await this.getFilesByTaskId(this.selectedTask);
      }
    },

    /**
     * Sends comments for a specific task.
     * @param {Number} taskId - The ID of the task.
     * @param commentModelId
     * @async
     */
    async sendComments(taskId, commentModelId) {
      try {
        //TODO::list last revisions id
        const allRevisionIds = this.revisionsList.lastRevisionIds;
        for (const revId of allRevisionIds) {
          await fileService.updateRevisionStatus(revId, 6);
        }
        //TODO:: Fix send comments id
        await this.addCommentGRC(taskId, commentModelId);
        this.commentSubmitPermissionPopup = false;
        this.modelLargeView = false;
        this.paginatedRevisionsShownByClient = [];
      } catch (error) {
        console.error('Error sending comments:', error);
      }
    },

    /**
     * Fetches the status comments.
     * @async
     */
    async fetchStatusComments() {
      try {
        this.commentStatusList = await commentService.getStatusComments();
      } catch (error) {
        console.error('Error fetching status comments:', error);
      }
    },

    /**
     * Sets the new comment position based on the event coordinates.
     * @param {Object} event - The event object.
     */
    setNewCommentPosition(event) {
      if (this.imagePined) {
        const rect = this.$refs.image[0].getBoundingClientRect();

        const getCoordinatePercentage = (position, offset, dimension) =>
            ((position - offset) / dimension) * 100;
        const coordX = getCoordinatePercentage(
            event.clientY,
            rect.top,
            rect.height
        );
        const coordY = getCoordinatePercentage(
            event.clientX,
            rect.left,
            rect.width
        );

        // Save the coordinates only if they are within the image bounds
        if (coordX >= 0 && coordX <= 100 && coordY >= 0 && coordY <= 100) {
          this.commentPin = {
            CoordinateX: coordX,
            CoordinateY: coordY,
          };
        }
        this.setValiderDisabled(false);
      }
    },

    /**
     * Cancels the current comment.
     */
    cancelComment() {
      this.imagePined = false;
      this.commentForm.content = '';
      this.commentReplayForm = [];
    },
    /**
     * Cancels the reply to a comment.
     * @param {Number} index - The index of the comment.
     */
    cancelCommentReply(index) {
      this.isCommentPopupOpen[index] = false;
    },

    /**
     * Opens the comment dialog.
     */
    openCommentDialog() {
      this.initZoomLevel();
      // Disable the "Valider" button
      this.setValiderDisabled(true);
      this.fetchComments(this.selectedRev.id);
      this.isCommentPopupOpen = !this.isCommentPopupOpen;
    },

    /**
     * Toggles the visibility of comment replies.
     * @param {Number} index - The index of the comment.
     */
    toggleCommentReplies(index, comment) {
      if (!this.showReplyComments[index] && comment.comments.length > 0) {
        this.showReplyComments[index] = true;
      } else if (this.showReplyComments[index]) {
        this.showReplyComments[index] = false;
      } else {
        this.showReplyComments[index] = true;
      }
    },

    /**
     * Edits the status of a comment.
     * @param {Object} comment - The comment object.
     * @param {Object} status - The new status object.
     * @async
     */
    async editStatusComment(comment, status) {
      try {
        const editedComment = {
          ...comment,
          status: status.id,
          static_status: comment.static_status,
          user: comment.user.id,
          revision: comment.revision.id,
          created_at: comment.created_at || new Date().toISOString(),
          updated_at: new Date().toISOString(),
        };
        let response = await commentService.editComment(
            editedComment
        );
        this.untreatedComment = response.total_comments.untreated_comments;
        this.treatedComment = response.total_comments.treated_comments;
        this.rejectedComment = response.total_comments.refused_comments;
        this.listenForStatusChanges(comment.id, status);
      } catch (error) {
        console.error('Error editing status comment:', error);
      }
    },

    /**
     * Listens for status changes and updates the comment status.
     * @param {Number} commentId - The ID of the comment.
     * @param {Object} newStatus - The new status object.
     */
    listenForStatusChanges(commentId, newStatus) {
      const comment = this.comments.find((c) => c.id === commentId);
      if (comment) {
        comment.status.color = newStatus.color;
        comment.status.id = newStatus.id;
      }
    },

    /**
     * Handles setting actions based on the status and item.
     * @param {Object} status - The status object.
     * @param {Object} item - The item to be acted upon.
     */
    handleSettingActions(status, item) {
      switch (status.title) {
        case settingActions.treat:
        case settingActions.refuse:
          this.editStatusComment(item, status);
          break;
        case settingActions.edit:
          item.isEditing = true;
          break;
        case settingActions.delete:
          // Implement delete action here
          break;
        default:
          console.error('Unknown action:', status.title);
      }
    },

    /**
     * it the revision status is en révision not alloaw change
     *  return Boolean
     * @param revision
     */
    revisionChangeAccess(revision) {
      return this.user.is_client && revision.status.id === STATUS_IN_REVISION;
    },
    getSvgData(base64String) {
      return `data:image/svg+xml;base64,${base64String}`;
    },
    titleLargeModal() {
      const viewText = this.getViewText(this.selectedRev.file.view);
      const taskTitle = this.selectedTaskTitle;
      const projectTitle = this.projectDetails.title;
      document.title = `${viewText} - ${taskTitle} - ${projectTitle} - Espace création STRATIS`;
    },
    showComment(index) {
      const clickedComment = this.comments[index];
      if (this.highlightedCommentId && this.highlightedCommentId !== clickedComment.id) {
        const previousCommentElement = document.getElementById(`comment-${this.highlightedCommentId}`);
        if (previousCommentElement) {
          previousCommentElement.style.backgroundColor = '';
        }
      }
      this.highlightedCommentId = clickedComment.id;
      this.$nextTick(() => {
        const commentElement = document.getElementById(`comment-${clickedComment.id}`);
        if (commentElement) {
          commentElement.scrollIntoView({behavior: 'smooth', block: 'center'});
          commentElement.style.backgroundColor = '#bebebe';
          setTimeout(() => {
            if (this.highlightedCommentId === clickedComment.id) {
              commentElement.style.backgroundColor = '';
            }
          }, 1000);
        } else {
          console.warn('Comment element not found for ID:', clickedComment.id);
        }
      });
    },
    showPin(commentId, commentColor) {
      if (this.highlightedPinId && this.highlightedPinId !== commentId) {
        const previousPinElement = document.getElementById(`pin-${this.highlightedPinId}`);
        if (previousPinElement) {
          previousPinElement.classList.remove('flash');
          previousPinElement.style.backgroundColor = commentColor;
        }
      }
      this.highlightedPinId = commentId;
      this.$nextTick(() => {
        const pinElement = document.getElementById(`pin-${commentId}`);
        if (pinElement) {
          pinElement.scrollIntoView({behavior: 'smooth', block: 'center'});
          pinElement.classList.add('flash');
          setTimeout(() => {
            if (this.highlightedPinId === commentId) {
              pinElement.classList.remove('flash');
              pinElement.style.backgroundColor = commentColor;
            }
          }, 3000);
        } else {
          console.warn('Pin element not found for ID:', commentId);
        }
      });
    },
    // Save the modified comment
    async saveEditComment(comment) {
      try {
        await commentService.editComment(comment);
        comment.isEditing = false; // Exit edit mode
      } catch (e) {
        console.error("error update comment ", e.message);
      }

    },
  }
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@mixin btn-style($background, $border) {
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: $background;
  width: fit-content;
  border: 1px solid $border;
  border-radius: 3px;
  font: normal normal normal 13px/18px Roboto;
}

.v-dialog--fullscreen {
  height: 100vh;
}

.view-image-text {
  z-index: 1;
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  padding: 5px;
  text-transform: capitalize;
  border-radius: 0 0 10px 0;
}

.view-image-text {
  width: 108px;
  font-size: 13px;
  font-weight: 800;
}

.view-image-text i {
  margin: 0 !important;
}

.view-container {
  flex-direction: column;
  display: flex;
  padding: 120px 50px;
  justify-content: center;

  .view {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: end;

    .view_other {
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: end;
    }

    & .image {
      object-fit: cover;
      width: 100%;
      box-shadow: 0px 0px 16px #00000029;
      border-radius: 20px;
      opacity: 1;
      margin-bottom: 10px;
    }

    & .image-client {
      object-fit: cover;
      width: 100%;
      box-shadow: 0px 0px 16px #00000029;
      border-radius: 20px;
      opacity: 1;
      margin-bottom: 10px;
      border: 10px solid #e5776c;
    }

    & .imageDesktop {
      height: 323px;
      width: 575px;
      object-position: 100% 0% !important;
    }

    & .imageMobile {
      width: 120px;
      height: 176px;
      object-position: 100% 0% !important;
    }

    & .imageLaptop {
      width: 456px;
      height: 257px;
      object-position: 100% 0% !important;
    }

    & .imageTablet {
      width: 167px;
      height: 219px;
      object-position: 100% 0% !important;
    }

    & .view-icon,
    .view-details-icon {
      color: #05a9f4;
      font: normal normal bold 18px/24px Roboto;
    }

    & .view-icon {
      font-size: 35px;
    }

    & .view-details {
      font: normal normal normal 13px/18px Roboto;
      letter-spacing: 0px;
      color: #d4d4d4;
      opacity: 1;
      margin-bottom: 5px;

      & .view-details-text {
        color: #d4d4d4;
      }
    }

    & .view-type {
      margin-top: 5px;
      display: flex;
      align-items: center;

      & .type-text {
        flex: 2;
        margin-right: 2px;
      }

      & .type-divider {
        flex-grow: 4;
        border: none;
        border-top: 1px solid #707070;
        margin: 0;
      }
    }

    & .view-title {
      color: #707070;
      text-align: left;
      font: normal normal normal 13px/18px Roboto;
      letter-spacing: 0px;
      opacity: 1;
      margin-bottom: 5px;
    }

    & .view-comment-red {
      @include btn-style(#c90d0f, #c90d0f);
    }

    & .view-comment-green {
      @include btn-style(#0f8000, #0f8000);
    }

    & .view-comment-grey {
      @include btn-style(#494949, #494949);
    }
  }

  .item-list__wrapper {
    display: flex;
    justify-content: center;
    grid-column-gap: 3%;
  }
}

.select-wrapper {
  position: relative;
  margin-left: 5px;

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px;
    outline: white;

    &::-ms-expand {
      display: none;
    }
  }

  &.open {
    select {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><polygon points="0,0 5,5 10,0" fill="%23fff" class="arrow"/></svg>');
    }
  }

  select {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><polygon points="0,5 5,0 10,5" fill="%23fff" class="arrow"/></svg>');
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  /* Define specific icon for each option */
  option[data-icon='mdi-cellphone']:before {
    background-position: 0px 0px;
    /* Position of the Mobile icon in the sprite */
  }

  option[data-icon='mdi-tablet']:before {
    background-position: -20px 0px;
    /* Position of the Tablet icon in the sprite */
  }

  option[data-icon='mdi-monitor']:before {
    background-position: -40px 0px;
    /* Position of the Desktop icon in the sprite */
  }
}

.header-third-block {
  margin-top: 10px;
}

.header-first-block {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1;

  & .headline {
    text-align: left;
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    cursor: pointer;
    padding: 0px;
  }

  & .header-headline {
    text-align: left;
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    cursor: default;
  }

  & .first-block-icon {
    color: #05a9f4;
    font: normal normal normal 13px/16px Font Awesome 6 Sharp;
    margin-bottom: 3px;
  }

  & .btn-icon-header {
    background: #f7f4f4 0% 0% no-repeat padding-box;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    opacity: 1;
  }
}

.inner-select-icon {
  color: #05a9f4;
  font: normal normal normal 12px/16px Font Awesome 6 Sharp;
  margin-bottom: 3px;
  margin-right: 2px;
}

.header-second-block {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1;

  & .comment-btn {
    background: #f7f4f4 0% 0% no-repeat padding-box;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    color: #30322f;
    opacity: 1;
    font: normal normal bold 14px/19px Roboto;
  }

  & .comment-enabled-btn {
    background: #3f9efe 0% 0% no-repeat padding-box;
    color: #ffffff;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    opacity: 1;
    font: normal normal bold 14px/19px Roboto;
  }

  & .comment-btn-icon {
    margin-right: 10px;
    margin-left: 5px;
    margin-top: 2px;
  }
}

.header-icon-menu-item {
  cursor: pointer;
}

.header-icon-menu-item:hover {
  background-color: #bbbb;
}

.my-custom-dialog {
  align-self: flex-end;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  position: relative;
  margin: 80px 10px 20px 5px;
  /* Top, Right, Bottom, Left */
  scrollbar-width: none; /* Hide scrollbar if needed */
  overflow-x: auto; /* Add horizontal scrolling */
}

/* For Webkit browsers (Chrome, Safari, etc.) */
.image-container::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  height: 0;
  /* Remove scrollbar space */
}

.badge {
  position: absolute;
  display: flex;
  justify-content: center;
  /* horizontally center the content */
  align-items: center;
  /* vertically center the content */
  color: white;
  border-radius: 14px;
  cursor: pointer;
  border: 4px solid #ffffff;
  box-shadow: 0px 0px 16px #000000cc;
  font: normal normal bold 13px/18px Roboto;
  width: 28px;
  height: 28px;
}

.pin-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  /* Adjust the size as needed */
  height: 30px;
  /* Adjust the size as needed */
  border: 2px dashed #05a9f4;
  /* Adjust the color as needed */
  border-radius: 60%;
}

.badge-avatar {
  top: 0;
  left: 0;
  position: absolute;
}

.comment-form {
  margin-top: 10px;
  padding: 20px;
}

.cancel-btn {
  background-color: transparent !important;
  color: #c5c2c2;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.cancel-btn:disabled {
  background-color: transparent !important;
  color: #c5c2c2;
}

.vdt-btn {
  background: #f7f4f4 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  color: #c5c2c2;
  opacity: 1;
}

.vdt-enabled-btn {
  background: #3f9efe 0% 0% no-repeat padding-box;
  color: #ffffff;

  border: 1px solid #d4d4d4;
  border-radius: 3px;
  opacity: 1;
}

.reply-list {
  padding: 15px 20px;
  font: normal normal bold 14px/19px Roboto;

  .reply-avatar {
    position: absolute;
    top: 0;
    left: 0;
  }

  .options-btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  .reply-title {
    padding-left: 20px;

    .reply-date {
      color: #707070;
      font: normal normal normal 12px/16px Roboto;
    }

    .reply-name {
      color: #494949;
    }
  }

  .reply-comment {
    padding: 5px 20px;
  }
}

nav {
  right: 0px;
  z-index: 1004;
  transform: translateX(0%);
  position: fixed;
  height: calc(100% - 64px);
  top: 78px !important;
  bottom: 0px;
  width: 30% !important;
}

.comment-popup {
  background: #f0f4f7 0% 0% no-repeat padding-box;
  opacity: 1;
}

.date {
  text-align: left;
  font: normal normal normal 13px/18px Roboto;
  letter-spacing: 0px;
  color: #494949;
  opacity: 1;
}

.eye-donwload-Icon {
  text-align: center;
  font: normal normal normal 16px/19px Font Awesome 6 Sharp;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.eye-donwload-Icon-Off {
  color: #c90d0f;
  text-align: center;
  font: normal normal normal 16px/19px Font Awesome 6 Sharp;
  letter-spacing: 0px;
  opacity: 1;
}

.bottom-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #dfe4e9;
}

.comment {
  left: 13%;
  padding: 0;
  margin: 0 auto;
  color: #494949;
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #494949;
  opacity: 1;
}

.comment-settings {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-c5c5c5);
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #00000029;
  border: 1px solid #c5c5c5;
  cursor: pointer;

  .settings-icon {
    font-size: 18px;
  }
}

.reply-btn {
  padding-top: 20px;
  color: #05a9f4;
  text-align: left;
  font: normal normal bold 14px/19px Roboto;
  letter-spacing: 0px;
  opacity: 1;
}

.comment-reply-input {
  padding-top: 10px;
}

.v-card-text {
  padding: 0rem;
}

.v-dialog > .v-overlay__content > .v-card > .v-card-text {
  padding: 0px;
}

.sticky-title {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  /* Adjust z-index as needed */
  background-color: #fff;
  /* Optional: Add a background color for better readability */
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #30322f;
  color: #d4d4d4;
  z-index: 1000;
}

.zoom-options {
  color: #d4d4d4;
}

.v-text-field--outlined {
  border-color: rgba(192, 0, 250, 0.986);
}

.filterDialog {
  max-width: 300px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom: 82px;
}

.filter-options {
  display: flex;
  flex-wrap: wrap;
}

.filter-options v-btn {
  margin-right: 10px;
  /* Adjust margin as needed */
  margin-bottom: 10px;
  /* Adjust margin as needed */
}

.selected {
  background: #05a9f4 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  color: #ffffff;
  margin-right: 10px;
  /* Adjust margin as needed */
  margin-bottom: 10px;
  /* Adjust margin as needed */
}

.not-selected {
  margin-right: 10px;
  /* Adjust margin as needed */
  margin-bottom: 10px;
  /* Adjust margin as needed */

  border: 1px solid var(--unnamed-color-d4d4d4);
  background: #f7f4f4 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  opacity: 1;
  color: #494949;
}

.permision-label {
  text-align: center;
  font: normal normal lighter 24px/19px Roboto;
  color: #494949;
  opacity: 1;
}

.permision-label-content {
  text-align: center;
  font: normal normal lighter 16px/18px Roboto;
  color: #494949;
  opacity: 1;
}

.filter-remove-btn {
  background-color: transparent !important;
  color: #30322f;
  border: none;
  border: 1px solid #30322f;
  padding: 5px 10px;
  cursor: pointer;
}

.custom-text-field > .v-text-field__icon--append-inner {
  color: red;
}

.image-container-responsive {
  display: flex;
  max-width: 100%;
  position: relative;
  scrollbar-width: none;
  overflow-x: auto;
  object-fit: cover;
}

/* For small screens (e.g., mobile devices) */
@media (max-width: 1174px) {
  .image-container-responsive {
    display: flex;
    flex-wrap: nowarp;
    flex: 1;
    max-width: 100%;
    position: relative;
    scrollbar-width: none;
    overflow-x: auto;
  }
}

.image-container-with-filter {
  cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAHlBMVEUAAAADqvUGqvYGqfMGqfQFqfQFqfQFqfQFqfT///9g5dsIAAAACHRSTlMAS1GAiMz4+raKmaUAAAABYktHRAnx2aXsAAAANUlEQVQY02NgwAc4OoCgARuHoQOMaM5hbmdgqDCAciyKGBjEWmCqwAAbh4GBNQDJExRzsAEAW5MYpcwhmJ4AAAAASUVORK5CYII='),
  auto;
  filter: sepia(100%) hue-rotate(45deg);
  border: 2px solid yellow;
}

.image-wrapper img {
  width: 100%;
  height: auto;
}

.mockup-view {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: monospace, monospace;
}

.dialog-circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Mono';
}

.dialog-circle span {
  font-size: 40px;
  font-weight: lighter;
  color: #fff;
}

.dialog-card {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: visible;
}

.dialog-large-circle {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #ffffff;
}
</style>
<style>
.header-select > .v-input__control {
  width: auto !important;
}

.v-img__img--contain {
  object-fit: cover !important;
  object-position: 100% 0% !important;
}

/* Media Query for mobile and tablet */
@media (max-width: 1200px) {
  .view-container {
    flex-direction: column;
    padding: 100px 50px !important;
  }

  .view {
    margin-bottom: 20px;
  }

  .view .imageDesktop,
  .view .imageMobile,
  .view .imageLaptop,
  .view .imageTablet {
    width: 100%;
    height: auto;
    object-position: 100% 0% !important;
  }

  .view .imageDesktop {
    width: 100% !important;
  }

  .view-image-text {
    width: 100px !important;
    font-size: 11px !important;
  }

  .view-image-text i {
    margin: 0 !important;
  }
}

.btn-custom {
  text-transform: capitalize !important;
  padding: 5px 10px;
  border: none;
  border-radius: 5px !important;
  opacity: 1 !important;
  color: #fff !important;
}

.btn-untreated {
  background-color: #c90d0f !important;
}

.btn-treated {
  background-color: #0f8000 !important;
}

.btn-rejected {
  background-color: #707070 !important;
}

.btn-active {
  background-color: rgba(187, 185, 185, 0.91) !important;
}

.btn-filter-off {
  padding: 5px 10px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  opacity: 1;
  background-color: #f7f4f4;
}

/* Style for the custom disabled button */
.custom-disabled-btn.v-btn:disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  background: #f7f4f4 0% 0% no-repeat padding-box !important;
  border: 1px solid #d4d4d4 !important;
  border-radius: 3px !important;
  color: #c5c2c2 !important;
}

.pictogram {
  display: inline-block;
  width: 14px;
  height: 14px;
  bottom: 10%;
}

.avatar-image {
  cursor: pointer;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.8) !important;
  font: normal normal bold 13px / 18px Roboto;
  width: 30px !important;
  height: 30px !important;
}

.v-dialog > .v-overlay__content > .v-card {

}

.custom-cursor:hover {
  cursor: pointer;
}

.highlighted {
  background-color: rgba(190, 190, 190, 0.91); /* Example highlight color */
  transition: background-color 0.5s ease; /* Smooth transition */
}

@keyframes flash {
  0% {
    background-color: #ff0000;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: #ffffff;
  }
}

.flash {
  animation: flash 1s ease-in-out infinite;
}

.comment-user, .comment {
  cursor: pointer;
}

.view-row__container {
  display: flex;
}

.other-list__wrapper {
  margin-top: 50px;
}

.btn-change-file {
  background-color: #c90d0f !important;
  cursor: pointer;
}

.btn-change-file:hover {
  color: rgba(255, 255, 255, 0.75)
}

.btn-validate-files {
  background-color: #0f8000 !important;
  cursor: pointer;
}

.btn-validate-files:hover {
  color: rgba(255, 255, 255, 0.75)
}

.btn-change-file-client-prev {
  border: 2px solid #FFF !important;
  margin-right: 12px
}

.btn-change-file-client-prev:hover {
  color: rgba(255, 255, 255, 0.75)
}

.btn-change-file-client-next {
  border: 2px solid #FFF !important;
  margin-left: 12px
}

.btn-change-file-client-next:hover {
  color: rgba(255, 255, 255, 0.75)
}
</style>

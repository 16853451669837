<template class="popup" id="modal-template">
  <v-card>
    <h2 class="title-popup">{{ msg }}</h2>
    <div class="container">
      <!-- Loader -->
      <div v-if="isLoading" class="loader">
        <span>Téléchargement des fichiers, veuillez patienter...</span>
      </div>
      <div class="dropzone-area" ref="dropZoneRef" :class="{ 'is-over': isOverDropZone }" @drop.prevent="handleFileDrop"
           @dragover.prevent="handleDragOver" @dragleave.prevent="handleDragLeave" @click="triggerFileInput">
        <input type="file" ref="fileInput" multiple @change="handleFileSelection" class="file-input"
               style="display: none"/>
        <span v-if="files.length === 0">Déposez les fichiers ici ou cliquez pour télécharger</span>
      </div>

      <!-- Display selected file names -->
      <ul v-if="files.length > 0">
        <li v-for="(file, index) in files" :key="index">
          {{ file.name }}
          <button @click="removeFile(index)"><span class="mdi mdi-close-thick"></span></button>
        </li>
      </ul>

      <!-- Submit Button -->
      <button class="submit-button" @click.prevent="handleClick" :disabled="isLoading"
              :class="{ 'btn-disable-custom': isLoading }">
        Enregistrer
      </button>

      <!-- Close button for modal -->
      <button class="close-button" @click="closeModel">Fermer</button>
    </div>
  </v-card>
</template>
<script>
import Button from "@/components/Button.vue";
import axios from "axios";

export default {
  components: {Button},
  data() {
    return {
      el: "#app",
      msg: '',
      file: null,
      files: [], // Array to store multiple files
      isOverDropZone: false,
      isLoading: false, // Loader state
    }
  },
  mounted() {
    console.log("dialog popup")
  },
  methods: {
    // Trigger file input when the dropzone is clicked

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    // Handle file selection via input
    handleFileSelection(event) {
      const selectedFiles = Array.from(event.target.files);
      this.files.push(...selectedFiles);
    },

    // Handle file drop into the dropzone
    handleFileDrop(event) {
      const droppedFiles = Array.from(event.dataTransfer.files);
      this.files.push(...droppedFiles);
      this.isOverDropZone = false; // Reset "is-over" after drop
    },

    // Handle drag over event to show visual effect
    handleDragOver() {
      this.isOverDropZone = true;
    },

    // Handle drag leave event to hide the effect
    handleDragLeave() {
      this.isOverDropZone = false;
    },

    // Remove file from the list
    removeFile(index) {
      this.files.splice(index, 1);
    },
    async handleClick(event) {
      // Prevent multiple clicks by disabling further event propagation
      if (this.isLoading) {
        event.preventDefault();
        return;
      }
      this.submitFiles();
    },
    async submitFiles() {
      if (this.files.length === 0) {
        console.log('No files selected');
        return;
      }

      this.isLoading = true; // Start the loader
      let formData = new FormData();
      this.files.forEach((file, index) => {
        formData.append(`file_${index}`, file);
      });

      try {
        const response = await axios.post('/file/jsonimagen/', formData, {
          headers: {'Content-Type': 'multipart/form-data'},
        });

        this.msg = response.data.status;
        this.successUploads(this.msg)
        this.closeModel();
      } catch (error) {
        console.error('Error uploading files:', error);
        this.msg = error.response?.data?.error || 'Error uploading files. Please try again.';
      } finally {
        this.isLoading = false; // End the loader
      }
    },
    closeModel() {
      this.$emit('closeModel', false);
      this.files = [];
    },
    successUploads(msg) {
      this.$emit('success', msg);
    }

  }
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.filterDialog {
  max-width: 300px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom: 82px;
}

.dropzone-area {
  border: 2px dashed #C90D0F;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.is-over {
  border-color: #4caf50;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.container {
  padding: 14px 7px;
}

h2.title-popup {
  padding: 9px 8px ;
  font-size: 16px ;
  color: #C90D0F ;
}

.popup {
  background-color: #f1f1f1;
  border: 1px solid #F7F4F4;
  border-radius: 5px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.2em;
  color: #353653;
}

.btn-disable-custom {
  background-color: #ccc; /* Light gray background */
  color: #666; /* Darker text to indicate it's inactive */
  opacity: 0.6; /* Slight transparency */
  pointer-events: none; /* Disables click interactions */
  cursor: not-allowed; /* Shows a 'not-allowed' cursor */
}

// .loader {
//   display: flex;
//   justify-content: center;
//   border: 16px solid #f3f3f3; /* Light grey */
//   border-top: 16px solid #3498db; /* Blue */
//   border-radius: 50%;
//   width: 120px;
//   height: 120px;
//   animation: spin 2s linear infinite;
// }

// @keyframes spin {
//   0% { transform: rotate(0deg); }
//   100% { transform: rotate(360deg); }
// }
</style>